import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { FlexElement } from './flex-element/flex-element.component';

type Props = {
  currentSlideIndex: number;
};

const ImageSlideContent = ({ currentSlideIndex }: Props): JSX.Element => (
  <FlexElement column gap={0.5} attributes={{ style: { textAlign: 'center' } }}>
    <h2>{dictionary.texts.imageSlideTitle(currentSlideIndex)}</h2>
    <p>{dictionary.texts.imageSlideDescription(currentSlideIndex)}</p>
  </FlexElement>
);

export { ImageSlideContent };
