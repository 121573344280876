import { useCallback } from 'react';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useOnBottomReached } from '../../domain/common/hooks/use-on-bottom-reached.hook';
import { usePagination } from '../../domain/common/hooks/use-pagination.hook';
import { leaderboardService } from '../../domain/leaderboard/leaderboard.service';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { LeaderboardElement } from '../elements/leaderboard-element/leaderboard-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { SpinnerElement } from '../elements/spinner-element/spinner-element.component';
import infoIcon from '../../assets/graphics/info-icon.svg';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { TitleContentTemplate } from '../templates/titled-content-template.component';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';

const PopularCreatorLeaderboardScreen = (): JSX.Element => {
  const paginateLeaderboard = useCallback(
    async (skip: number, limit: number) => {
      const { data: items } =
        await leaderboardService.getPopularContentCreators({
          offset: skip,
          limit,
        });
      return items;
    },
    [],
  );
  const { items, nextPage, isLoading } = usePagination(paginateLeaderboard, 10);
  useOnBottomReached(nextPage);

  const { show: showSheet, close: closeSheet } = useSheetContext();
  const handleShowInfoSheet = useCallback(() => {
    showSheet(
      <TitleContentTemplate
        title={dictionary.literals.aboutTheLeaderboards}
        description={dictionary.texts.leaderboardInfoDescription}
      >
        <ActionButtonElement
          onClick={closeSheet}
          flavor="primaryLightScheme-rounded"
        >
          <h3>{dictionary.literals.close}</h3>
        </ActionButtonElement>
      </TitleContentTemplate>,
    );
  }, [closeSheet, showSheet]);

  return (
    <ScreenContainerElement
      title={dictionary.literals.leaderboard}
      subTitle={dictionary.literals.globalPopularCreators}
      titleControls={
        <img
          src={infoIcon}
          style={{
            cursor: 'pointer',
          }}
          alt="icon"
          width={28}
          height={28}
          onClick={handleShowInfoSheet}
        />
      }
    >
      <FlexElement fullWidth column gap>
        {items.map((item) => (
          <LeaderboardElement
            key={item.user.id}
            position={item.position}
            user={item.user}
            points={item.score}
          />
        ))}
        {isLoading && <SpinnerElement />}
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { PopularCreatorLeaderboardScreen };
