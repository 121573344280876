import { ILeaderboardItem } from '@hulanbv/toss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useOnBottomReached } from '../../domain/common/hooks/use-on-bottom-reached.hook';
import { usePagination } from '../../domain/common/hooks/use-pagination.hook';
import { useFloatingActionButtonContext } from '../../domain/floating-action-button/floating-action-button-context.hook';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { leaderboardService } from '../../domain/leaderboard/leaderboard.service';
import { useSpotService } from '../../domain/spot/spot-service.hook';
import { submissionService } from '../../domain/submission/submission.service';
import { useUserMovementsService } from '../../domain/user-movements/user-movements-service.hook';
import { FeaturedUsersElement } from '../elements/featured-users-element/featured-users-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { SpinnerElement } from '../elements/spinner-element/spinner-element.component';
import { ViewSubmissionElement } from '../elements/view-submission-element/view-submission-element.component';
import { SubmissionFormTemplate } from '../templates/submission-form-template.component';
import UploadIconLightGraphic from '../../assets/graphics/upload-icon-light.svg';
import { HighlightableElement } from '../elements/highlightable-element/highlightable-element.component';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { sleep } from '../../domain/common/utilities/sleep.utility';
import { TitleContentTemplate } from '../templates/titled-content-template.component';
import { userMovementsService } from '../../domain/user-movements/user-movements.service';

const SpotScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const [leaderboardItems, setLeaderboardItems] = useState<
    ILeaderboardItem[] | null
  >(null);
  const params = useParams<'spotId'>();
  const { session } = useAuthenticationContext();

  const { useGet: useGetSpot, useHttpOptions: useGetSpotHttpOptions } =
    useSpotService();
  const {
    useGetAll: useGetAllUserMovements,
    useHttpOptions: useGetAllUserMovementsHttpOptions,
  } = useUserMovementsService();

  const getSpotHttpOptions = useGetSpotHttpOptions(() => ({
    populate: [
      'banner',
      'area.model',
      'area.texture',
      'area.spots',
      'area.navigations',
    ],
  }));

  // This variable is used for checking the user activity in the spot for the
  // last 10 minutes. If the user has been active in the spot for the last 10
  // minutes, the user is considered to be in the spot.
  const tenMinutesAgoDate = new Date(Date.now() - 1000 * 60 * 10).toISOString();
  const getAllUserMovements = useGetAllUserMovementsHttpOptions(() => ({
    match: {
      createdAt: {
        $gte: tenMinutesAgoDate,
      },
    },
    limit: 1,
    distinct: 'userId',
  }));

  const { document: spot } = useGetSpot(params.spotId, getSpotHttpOptions);
  const { headers } = useGetAllUserMovements(getAllUserMovements);

  const recentUserMovements = useMemo(
    () => Number(headers?.get('x-total-count')),
    [headers],
  );

  useEffect(() => {
    (async () => {
      if (!params.spotId || !session?.user?.id || !spot?.areaId) {
        return;
      }
      await userMovementsService.post({
        coordinates: spot?.coordinates ?? [0, 0, 0],
        areaId: spot?.areaId ?? '',
        userId: session?.user?.id,
      });
    })();
  }, [params.spotId, session?.user?.id, spot?.areaId, spot?.coordinates]);

  useEffect(() => {
    (async () => {
      const { data: items } =
        await leaderboardService.getPopularContentCreatorsOfSpot(
          params.spotId,
          {
            limit: 3,
          },
        );
      setLeaderboardItems(items);
    })();
  }, [params.spotId]);

  const paginateSubmissions = useCallback(
    async (skip: number, limit: number) => {
      const { data: submissions } = await submissionService.getAll({
        match: {
          spotId: params.spotId,
        },
        sort: ['-createdAt'],
        populate: [
          'author.profileImage',
          'assets',
          'spot.area.model',
          'spot.area.texture',
          'spot.area.spots',
          'spot.area.navigations',
          {
            path: 'likes',
            match: { authorId: session?.userId },
          },
          {
            path: 'comments',
            limit: 2,
            sort: ['-createdAt'],
            populate: [
              { path: 'author', populate: [{ path: 'profileImage' }] },
            ],
          },
        ],
        offset: skip,
        limit,
      });

      return submissions;
    },
    [params.spotId, session?.userId],
  );
  const {
    items: submissions,
    nextPage,
    isLoading,
    reset: resetSubmissions,
  } = usePagination(paginateSubmissions, 10);
  useOnBottomReached(nextPage);

  const { show: showSheet, close: closeSheet } = useSheetContext();
  const handleShowSubmissionForm = useCallback(() => {
    const onSubmit = async (formData: FormData) => {
      if (!params.spotId || !session?.userId) {
        return;
      }
      formData.set('spotId', params.spotId);
      formData.set('authorId', session?.userId);
      try {
        await submissionService.post(formData);
        await sleep(1000);
        showSheet(
          <FlexElement column gap={2} fullWidth verPadding={2} align="center">
            <h1>{dictionary.literals.contentPublished}</h1>
            <ActionButtonElement
              onClick={closeSheet}
              flavor="primaryLightScheme-rounded"
            >
              <h3>{dictionary.literals.viewContribution}</h3>
            </ActionButtonElement>
          </FlexElement>,
        );
        await resetSubmissions();
      } catch {
        // todo show notification on fail
      }
    };
    showSheet(
      <TitleContentTemplate
        title={dictionary.literals.contributeToX(dictionary.literals.spot)}
        description={dictionary.texts.creatingNewSubmisionDescription}
      >
        <SubmissionFormTemplate onSubmit={onSubmit} />
      </TitleContentTemplate>,
    );
  }, [closeSheet, params.spotId, resetSubmissions, session?.userId, showSheet]);

  useFloatingActionButtonContext({
    callback: handleShowSubmissionForm,
    flavor: 'primary-circle',
    imageSource: UploadIconLightGraphic,
  });

  if (!spot) {
    return (
      <FlexElement gap={0.5} column minHeight="95vh">
        <SpinnerElement />
        <p>{dictionary.literals.loading}...</p>
      </FlexElement>
    );
  }

  return (
    <ScreenContainerElement
      coverImageUrl={spot?.banner?.thumbUrl}
      spot={spot}
      title={spot?.name}
      subTitle={dictionary.texts.xPeopleAtSpot(recentUserMovements)}
    >
      <FlexElement align={'unset'} column gap>
        <HighlightableElement
          onClick={() =>
            navigate(`/leaderboard/popular-creator/spot/${params.spotId}`)
          }
        >
          {leaderboardItems && leaderboardItems.length >= 3 && (
            <FeaturedUsersElement items={leaderboardItems} />
          )}
        </HighlightableElement>
        <FlexElement>
          {isLoading === false && submissions.length === 0 && (
            <h5>{dictionary.texts.xHasNoSubmissions(spot?.name)}</h5>
          )}
          {submissions.length > 0 && (
            <FlexElement column gap fullWidth align="stretch">
              {submissions?.map((submission) => (
                <ViewSubmissionElement
                  key={submission.id}
                  submission={submission}
                />
              ))}
            </FlexElement>
          )}
          {isLoading === true && <SpinnerElement />}
        </FlexElement>
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { SpotScreen };
