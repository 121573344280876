import { useParams } from 'react-router-dom';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { useSubmissionService } from '../../domain/submission/submission-service.hook';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { ViewSubmissionElement } from '../elements/view-submission-element/view-submission-element.component';

const SubmissionScreen = (): JSX.Element => {
  const params = useParams<'submissionId'>();
  const { session } = useAuthenticationContext();

  const {
    useGet: useGetSubmission,
    useHttpOptions: useGetSubmissionHttpOptions,
  } = useSubmissionService();

  const getSubmissionHttpOptions = useGetSubmissionHttpOptions(() => ({
    sort: ['-createdAt'],
    populate: [
      'author.profileImage',
      'assets',
      'spot.area.model',
      'spot.area.texture',
      'spot.area.spots',
      'spot.area.navigations',
      {
        path: 'likes',
        match: { authorId: session?.userId },
      },
    ],
  }));

  const { document: submission } = useGetSubmission(
    params.submissionId,
    getSubmissionHttpOptions,
  );

  return (
    <ScreenContainerElement>
      <FlexElement column gap fullWidth align="stretch">
        {submission && (
          <ViewSubmissionElement
            shouldShowFullSubmission
            submission={submission}
          />
        )}
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { SubmissionScreen };
