import { HttpService, IResponse } from 'nest-utilities-client';

class Service extends HttpService {
  public currentAuthorizationToken = '';

  async getHeaders() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- naming convention is not respected for header
      Authorization: this.currentAuthorizationToken,
    };
  }

  async onRequestError(error: IResponse<Error>) {
    if (error.status === 401) {
      // TODO -- Implement
    }
  }
}

const httpService = new Service();

export { httpService };
