import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useOnBottomReached } from '../../domain/common/hooks/use-on-bottom-reached.hook';
import { usePagination } from '../../domain/common/hooks/use-pagination.hook';
import { useFloatingActionButtonContext } from '../../domain/floating-action-button/floating-action-button-context.hook';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { submissionService } from '../../domain/submission/submission.service';
import { useUserService } from '../../domain/user/user-service.hook';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ProfilePictureElement } from '../elements/profile-picture-element/profile-picture-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { SpinnerElement } from '../elements/spinner-element/spinner-element.component';
import { ViewSubmissionElement } from '../elements/view-submission-element/view-submission-element.component';
import { SubmissionFormTemplate } from '../templates/submission-form-template.component';
import UploadIconLightGraphic from '../../assets/graphics/upload-icon-light.svg';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { sleep } from '../../domain/common/utilities/sleep.utility';
import { EditElement } from '../elements/edit-element/edit-element.component';
import { userService } from '../../domain/user/user.service';
import { EditProfileFormTemplate } from '../templates/edit-profile-form-template.component';
import { TitleContentTemplate } from '../templates/titled-content-template.component';

const ProfileScreen = (): JSX.Element => {
  const params = useParams<'userId'>();
  const { session, validate } = useAuthenticationContext();

  const { useGet: useGetUser, useHttpOptions: useGetUserHttpOptions } =
    useUserService();

  const getUserHttpOptions = useGetUserHttpOptions(() => ({
    populate: ['profileImage'],
  }));

  const { document: user } = useGetUser(params.userId, getUserHttpOptions);

  const paginateSubmissions = useCallback(
    async (skip: number, limit: number) => {
      const { data: submissions } = await submissionService.getAll({
        match: { authorId: params.userId },
        sort: ['-createdAt'],
        populate: [
          'author.profileImage',
          'assets',
          'spot.area.model',
          'spot.area.texture',
          'spot.area.spots',
          'spot.area.navigations',
          {
            path: 'likes',
            match: { authorId: session?.userId },
          },
          {
            path: 'comments',
            limit: 2,
            sort: ['-createdAt'],
            populate: [
              { path: 'author', populate: [{ path: 'profileImage' }] },
            ],
          },
        ],
        offset: skip,
        limit,
      });
      return submissions;
    },
    [params.userId, session?.userId],
  );
  const {
    items: submissions,
    nextPage,
    isLoading,
    reset: resetSubmissions,
  } = usePagination(paginateSubmissions, 10);
  useOnBottomReached(nextPage);

  const { show: showSheet, close: closeSheet } = useSheetContext();
  const handleShowSubmissionForm = useCallback(() => {
    const onSubmit = async (formData: FormData) => {
      if (session?.userId !== params.userId) {
        return;
      }
      formData.set('authorId', session?.userId ?? '');
      try {
        await submissionService.post(formData);
        await sleep(1000);
        showSheet(
          <TitleContentTemplate title={dictionary.literals.contentPublished}>
            <ActionButtonElement
              onClick={closeSheet}
              flavor="primaryLightScheme-rounded"
            >
              <h3>{dictionary.literals.viewContribution}</h3>
            </ActionButtonElement>
          </TitleContentTemplate>,
        );
        await resetSubmissions();
      } catch {
        // TODO -- show notification on fail
      }
    };
    showSheet(
      <TitleContentTemplate
        title={dictionary.literals.contributeToX(dictionary.literals.profile)}
        description={dictionary.texts.creatingNewSubmisionDescription}
      >
        <SubmissionFormTemplate onSubmit={onSubmit} />
      </TitleContentTemplate>,
    );
  }, [closeSheet, params.userId, resetSubmissions, session?.userId, showSheet]);

  const handleOnSubmitProfileChange = useCallback(
    async (formData: FormData) => {
      if (session?.user?.id !== params.userId) {
        return;
      }

      formData.append('id', session?.user?.id ?? '');
      try {
        await userService.patch(formData);
        closeSheet();
        validate();
      } catch {
        // TODO: notification when error accurs
      }
    },
    [closeSheet, params.userId, session?.user?.id, validate],
  );

  const handleShowEditDescriptionSheet = useCallback(() => {
    showSheet(
      <EditProfileFormTemplate
        title={dictionary.literals.editYourProfile}
        biography={user?.bio}
        onSubmit={handleOnSubmitProfileChange}
      />,
    );
  }, [handleOnSubmitProfileChange, showSheet, user?.bio]);

  useFloatingActionButtonContext({
    isHidden: session?.userId !== params.userId,
    callback: handleShowSubmissionForm,
    flavor: 'primary-circle',
    imageSource: UploadIconLightGraphic,
  });

  return (
    <ScreenContainerElement>
      <FlexElement column gap>
        <ProfilePictureElement
          profileImage={user?.profileImage}
          userId={user?.id}
          size={150}
        />
        <FlexElement column gap={0.5}>
          <h1>
            {user?.firstName} {user?.lastName}
          </h1>
          <FlexElement>
            <h6>{user?.bio ?? dictionary.texts.xHasNoBio(user?.firstName)}</h6>
            {session?.userId === params.userId && (
              <EditElement onClick={handleShowEditDescriptionSheet} />
            )}
          </FlexElement>
        </FlexElement>
        <FlexElement gap={2}>
          <FlexElement column>
            <h1>{user?.submissionsCount ?? '-'}</h1>
            <h6>{dictionary.literals.uploads}</h6>
          </FlexElement>
          <FlexElement column>
            <h1>{user?.points ?? '-'}</h1>
            <h6>{dictionary.literals.points}</h6>
          </FlexElement>
          <FlexElement column>
            <h1>{user?.receivedLikesCount ?? '-'}</h1>
            <h6>{dictionary.literals.likes}</h6>
          </FlexElement>
        </FlexElement>
        {isLoading === false && submissions.length === 0 && (
          <h5>{dictionary.texts.xHasNoSubmissions(user?.firstName)}</h5>
        )}
        {submissions.length > 0 && (
          <FlexElement column gap fullWidth align="stretch">
            {submissions?.map((submission) => (
              <ViewSubmissionElement
                key={submission.id}
                submission={submission}
              />
            ))}
          </FlexElement>
        )}
        {isLoading === true && <SpinnerElement />}
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { ProfileScreen };
