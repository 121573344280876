import {
  CSSProperties,
  TouchEvent as TouchImageEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type IImageZoomHook = {
  imageRef: HTMLImageElement | null;
  imageStyles: CSSProperties;
  backgroundOpacity: number;
  handleTouchStart: (event: TouchImageEvent<HTMLImageElement>) => void;
};

/**
 * This useImageZoom hook is used to handle the zooming of an image. When the
 * user touches the screen with two fingers, the image will be able to zoom in
 * and out. The user will also be able to move the image around the screen when
 * they are zooming in.
 */
function useImageZoom(): IImageZoomHook {
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const [startPositions, setStartPositions] = useState<
    [[number, number], [number, number]] | null
  >(null);

  const [zoom, setZoom] = useState<number>(1);
  const [top, setTop] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  /**
   * When the user zooms in on the image, the background should become more opaque
   * to make the image stand out more. 0.3 is the minimum opacity, and 0.8 is the
   * maximum opacity.
   */
  const backgroundOpacity = useMemo(() => {
    const opacity = 0.3 + (zoom - 1) * 0.5;
    return Math.min(0.8, Math.max(0.3, opacity));
  }, [zoom]);

  /**
   * The image styles are used to position the zoomable image on the screen.
   * The image should be placed on position of the original image, and then
   * the user will be able to zoom in and out of the image. The image will also
   * be able to be moved around the screen when the user has zoomed in.
   */
  const imageStyles: CSSProperties = useMemo(() => {
    const styles: CSSProperties =
      {
        height: imageRef?.height,
        width: imageRef?.width,
        transform: `scale(${zoom}) translate(${left}px, ${top}px)`,
        left: imageRef?.getBoundingClientRect().left,
        top: imageRef?.getBoundingClientRect().top,
      } ?? {};

    return styles;
  }, [imageRef, left, top, zoom]);

  /**
   * Function to handle the touch start event. When the user touches the screen
   * with two fingers, we check if they are touching the same image. If they are,
   * we store the starting positions of the fingers. We also store the image
   * reference, so we can use the same stylings for the zoomed image.
   */
  const handleTouchStart = useCallback(
    (event: TouchImageEvent<HTMLImageElement>) => {
      if (event.touches[0] === undefined || event.touches[1] === undefined) {
        setStartPositions(null);
        setImageRef(null);
        return;
      }

      setStartPositions([
        [event.touches[0].clientX, event.touches[0].clientY],
        [event.touches[1].clientX, event.touches[1].clientY],
      ]);

      setImageRef(event.currentTarget);
    },
    [],
  );

  /**
   * Function to handle the touch move event. When the user moves their fingers
   * on the screen, we need to calculate the new zoom and position of the image.
   */
  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      if (
        imageRef === null ||
        startPositions === null ||
        event.touches[0] === undefined ||
        event.touches[1] === undefined
      ) {
        return;
      }

      // Prevent the page from scrolling.
      document.body.style.overflow = 'hidden';

      // Calculate the distance between the start positions of the fingers
      // and the current positions of the fingers.
      const startTouchDistance = Math.hypot(
        startPositions[0][0] - startPositions[1][0],
        startPositions[0][1] - startPositions[1][1],
      );
      const currentTouchDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY,
      );

      const zoomAdjustment = Math.max(
        1,
        currentTouchDistance / startTouchDistance,
      );

      setZoom(zoomAdjustment);

      // Calculate the X distance that the fingers have moved since the start
      // positions.
      const leftMove =
        event.touches[0].clientX -
        startPositions[0][0] +
        event.touches[1].clientX -
        startPositions[1][0];

      // Calculate the Y distance that the fingers have moved since the start
      // positions.
      const topMove =
        event.touches[0].clientY -
        startPositions[0][1] +
        event.touches[1].clientY -
        startPositions[1][1];

      setTop(topMove / 2 / zoomAdjustment);
      setLeft(leftMove / 2 / zoomAdjustment);
    },
    [imageRef, startPositions],
  );

  /**
   * Function to handle the touch end event. When the user stops touching the
   * screen, we need to reset the image reference, top, left and zoom. We also
   * need to allow the page to scroll again.
   */
  const handleTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (!imageRef) {
        return;
      }
      document.body.style.overflow = '';
      setImageRef(null);
      setTop(0);
      setLeft(0);
      setZoom(1);
    },
    [imageRef],
  );

  useEffect(() => {
    document.body.addEventListener('touchmove', handleTouchMove);
    document.body.addEventListener('touchend', handleTouchEnd);
    return () => {
      document.body.removeEventListener('touchmove', handleTouchMove);
      document.body.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd, handleTouchMove]);

  return {
    imageRef,
    imageStyles,
    backgroundOpacity,
    handleTouchStart,
  };
}

export { useImageZoom };
