import { ReactNode } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './highlightable-element.module.css';

type Props = {
  children: ReactNode;
  fullWidth?: boolean;
  onClick?: () => void | Promise<void>;
};

const HighlightableElement = ({
  children,
  fullWidth,
  onClick,
}: Props): JSX.Element => (
  <div
    onClick={onClick}
    className={join(styles.element, fullWidth && styles.fullWidth)}
  >
    {children}
  </div>
);

export { HighlightableElement };
