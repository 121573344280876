import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Bootstrap } from './components/bootstrap.component';
import './styles/animations.module.css';
import './styles/document.module.css';
import './styles/text-styles.module.css';
import './styles/fonts.module.css';

const element = document.createElement('div');
const root = createRoot(element);
const bootstrap = createElement(Bootstrap);
document.body.appendChild(element);
root.render(bootstrap);
