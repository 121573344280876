import { IArea } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class AreaService extends CrudService<IArea> {
  constructor() {
    super([environment.apiUrl, 'areas'].join('/'), httpService);
  }
}

const areaService = new AreaService();

export { areaService };
