import { CSSProperties, HTMLAttributes, ReactNode, useMemo } from 'react';
import styles from './flex-element.module.css';

type Props = {
  children: ReactNode;
  column?: boolean;
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  gap?: boolean | number;
  padding?: boolean | number;
  horPadding?: boolean | number;
  verPadding?: boolean | number;
  minHeight?: number | string;
  maxWidth?: number | string;
  fullWidth?: boolean;
  attributes?: HTMLAttributes<HTMLDivElement>;
  isRelative?: boolean;
  grow?: number;
  $debug?: boolean;
};

const FlexElement = ({
  children,
  attributes,
  ...props
}: Props): JSX.Element => {
  const className = useMemo<string>(() => {
    const className = [
      styles.element,
      props.column === true && styles.column,
      props.isRelative === true && styles.relative,
      typeof props.gap === 'boolean' && styles.gap,
      typeof props.padding === 'boolean' && styles.padding,
      typeof props.horPadding === 'boolean' && styles.horizontalPadding,
      typeof props.verPadding === 'boolean' && styles.verticalPadding,
      typeof props.fullWidth === 'boolean' && styles.fullWidth,
      props.$debug && styles.debug,
    ];
    return className.filter(Boolean).join(' ');
  }, [props]);

  const style = useMemo<CSSProperties>(() => {
    const style: CSSProperties = {
      justifyContent: props.justify,
      alignItems: props.align,
      gap: typeof props.gap === 'number' ? props.gap * 20 : undefined,
      padding:
        typeof props.padding === 'number' ? props.padding * 20 : undefined,
      paddingTop:
        typeof props.verPadding === 'number'
          ? props.verPadding * 20
          : undefined,
      paddingLeft:
        typeof props.horPadding === 'number'
          ? props.horPadding * 20
          : undefined,
      minHeight: props.minHeight,
      maxWidth: props.maxWidth,
      flexGrow: props.grow,
    };
    style.paddingRight = style.paddingLeft;
    style.paddingBottom = style.paddingTop;

    // Not stripping the undefined fields from the style object will result in
    // issues where the undefined directional padding will result in the
    // element being rendered with a padding of 0 alltogether.
    const strippedStyle = Object.fromEntries(
      Object.entries(style).filter(([index, value]) => value !== undefined),
    );
    return strippedStyle;
  }, [props]);

  return (
    <div
      {...attributes}
      style={{ ...style, ...attributes?.style }}
      className={`${className} ${attributes?.className}`}
    >
      {children}
    </div>
  );
};

export { FlexElement };
