import { useContext } from 'react';
import { AuthenticationContext } from './authentication.context';
import { useAuthentication } from './authentication.hook';

// Wraps the AuthenticationContext in a hook.
const useAuthenticationContext = (): ReturnType<typeof useAuthentication> => {
  const context = useContext(AuthenticationContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
};

export { useAuthenticationContext };
