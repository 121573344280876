import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import firstPhoto from '../../assets/images/tutorial-image-0.jpg';
import secondPhoto from '../../assets/images/tutorial-image-1.jpg';
import thirdPhoto from '../../assets/images/tutorial-image-2.jpg';
import fourthPhoto from '../../assets/images/tutorial-image-3.jpg';
import { ImageCarouselElement } from '../elements/image-carousel-element/image-carousel.component';
import { useTutorialContext } from '../../domain/tutorial/tutorial-context.hook';

const imageArray = [firstPhoto, secondPhoto, thirdPhoto, fourthPhoto];

const TutorialScreen = (): JSX.Element => {
  const { setHasSeenTutorial } = useTutorialContext();

  const handleClickContinue = () => {
    setHasSeenTutorial(true);
  };

  return (
    <ScreenContainerElement title={dictionary.literals.welcome}>
      <FlexElement fullWidth column gap>
        <ImageCarouselElement slides={imageArray} />
        <FlexElement>
          <ActionButtonElement
            onClick={handleClickContinue}
            flavor="primaryLightScheme-rounded"
          >
            <h3>{dictionary.literals.enterTheOtherSide}</h3>
          </ActionButtonElement>
        </FlexElement>
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { TutorialScreen };
