import { IUserMovement } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class UserMovementsService extends CrudService<IUserMovement> {
  constructor() {
    super([environment.apiUrl, 'user-movements'].join('/'), httpService);
  }
}

const userMovementsService = new UserMovementsService();

export { userMovementsService };
