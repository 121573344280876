/**
 * Directly throws an error informing the user that the environment is not set
 * up correctly.
 * @param key The key of the environment variable
 */
const throwEnvironmentException = (key: string): never => {
  throw new Error(`Environment variable ${key} is not defined`);
};

/**
 * Environment variables defined in the dot env file.
 */
const environment = {
  apiUrl:
    process.env.REACT_APP_API_URL ||
    throwEnvironmentException('REACT_APP_API_URL'),
  surfnetUrl:
    process.env.REACT_APP_SURFNET_URL ||
    throwEnvironmentException('REACT_APP_SURFNET_URL'),
  environment:
    (process.env.REACT_APP_ENVIRONMENT as 'DEV' | 'ACC' | 'PROD') ||
    throwEnvironmentException('REACT_APP_ENVIRONMENT'),
};

export { environment };
