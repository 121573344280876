import { FormEvent } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './edit-element.module.css';

type Props = {
  isProfilePictureEdit?: boolean;
  onChangeProfilePicture?: (event: FormEvent<HTMLInputElement>) => void;
  onClick?: () => void;
};

const EditElement = ({
  isProfilePictureEdit,
  onChangeProfilePicture,
  onClick,
}: Props): JSX.Element => (
  <>
    {!isProfilePictureEdit && <div className={styles.edit} onClick={onClick} />}
    {isProfilePictureEdit && (
      <label className={join(styles.edit, styles.changePicture)}>
        <input
          type="file"
          className={styles.input}
          accept=".jpg,.jpeg,.png"
          onChange={onChangeProfilePicture}
          required
        />
      </label>
    )}
  </>
);

export { EditElement };
