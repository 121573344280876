import { FC, Fragment, ReactNode, useCallback, useState } from 'react';
import { ReactComponent as CheckmarkSvg } from '../../../assets/graphics/checkmark.svg';
import styles from './checkbox-element.module.css';
import { join } from '../../../domain/common/utilities/join.utility';

export interface ICheckboxProps {
  attributes?: {
    isDefaultChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
    required?: boolean;
    value?: string;
    name?: string;
  };
  label: ReactNode;
}

export const Checkbox: FC<ICheckboxProps> = (props) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    props.attributes?.isDefaultChecked ?? false,
  );

  const handleOnClickToggle = useCallback(() => {
    const isCheckToggled = !isChecked;

    setIsChecked(isCheckToggled);
    props.attributes?.onChange?.(isCheckToggled);
  }, [isChecked, props]);

  return (
    <Fragment>
      {props.attributes?.name && (
        <input
          type="hidden"
          value={isChecked.toString()}
          name={props.attributes.name}
        />
      )}
      <div className={styles.checkboxContainer} onClick={handleOnClickToggle}>
        <div className={join(styles.checkbox, isChecked && styles.checked)}>
          {isChecked === true && (
            <CheckmarkSvg className={styles.checkmark} width={15} height={15} />
          )}
        </div>
        <p>{props.label}</p>
      </div>
    </Fragment>
  );
};
