import { useCallback, useState } from 'react';
import { FloatingActionButtonOptions } from './floating-action-button-options.type';

type IFloatingActionButtonHook = {
  isActive: boolean;
  options?: FloatingActionButtonOptions;
  show: (options: FloatingActionButtonOptions) => void;
  close: () => void;
};

function useFloatingActionButton(): IFloatingActionButtonHook {
  const [isActive, setIsActive] = useState(false);
  const [options, setOptions] = useState<FloatingActionButtonOptions>();

  const show = useCallback((options: FloatingActionButtonOptions) => {
    setIsActive(true);
    setOptions(options);
  }, []);

  const close = useCallback(() => {
    setIsActive(false);
    setOptions(undefined);
  }, []);

  return {
    isActive,
    options,
    show,
    close,
  };
}

export { useFloatingActionButton };
