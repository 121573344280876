import { ILeaderboardItem, IUser } from '@hulanbv/toss';
import { FormEvent, useCallback, useMemo } from 'react';
import styles from './profile-picture-element.module.css';
import defaultProfilePictureGraphic from '../../../assets/graphics/default-profile-picture.svg';
import { FlexElement } from '../flex-element/flex-element.component';
import { EditElement } from '../edit-element/edit-element.component';
import { useAuthenticationContext } from '../../../domain/authentication/authentication-context.hook';
import { assetService } from '../../../domain/asset/asset.service';
import { userService } from '../../../domain/user/user.service';

const thumbSizeBreakpoint = 100;

type Props = {
  profileImage?:
    | IUser['profileImage']
    | ILeaderboardItem['user']['profileImage'];
  userId?: IUser['id'];
  size?: number | string;
};

const ProfilePictureElement = ({
  profileImage,
  userId,
  size = 25,
}: Props): JSX.Element => {
  const { session, validate } = useAuthenticationContext();

  const url = useMemo(() => {
    if (size > thumbSizeBreakpoint) {
      return profileImage?.url;
    }
    return profileImage?.thumbUrl;
  }, [profileImage, size]);

  const changeProfilePicture = useCallback(
    async (event: FormEvent<HTMLInputElement>) => {
      try {
        const profileImageAsset = event.currentTarget.files?.[0];
        if (!profileImageAsset) {
          return;
        }

        const formData = new FormData();
        formData.append('file', profileImageAsset);
        const { data: asset } = await assetService.post(formData);

        await userService.patch({
          id: session?.userId,
          profileImageId: asset.id,
        });

        validate();
      } catch {
        // TODO: notification when error accurs
      }
    },
    [session?.userId, validate],
  );

  return (
    <FlexElement isRelative attributes={{ style: { width: size } }}>
      <div
        className={styles.element}
        style={{
          width: size,
          backgroundImage: `url(${url ?? defaultProfilePictureGraphic})`,
        }}
      />
      {size > thumbSizeBreakpoint && userId === session?.userId && (
        <EditElement
          isProfilePictureEdit
          onChangeProfilePicture={changeProfilePicture}
        />
      )}
    </FlexElement>
  );
};

export { ProfilePictureElement };
