import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { TogglebarElement } from '../elements/togglebar-element/togglebar-element.component';
import mapMenuItemIconGraphic from '../../assets/graphics/map-menu-item-icon.svg';
import listMenuItemIconGraphic from '../../assets/graphics/list-menu-item-icon.svg';

type Props = {
  activeIndex: number;
};

const HomeToggleBarTemplate = ({ activeIndex }: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleToggle = useCallback(() => {
    if (activeIndex === 0) {
      navigate('/map');
    } else {
      navigate('/');
    }
  }, [activeIndex, navigate]);

  return (
    <TogglebarElement
      activeIndex={activeIndex}
      options={[
        { label: dictionary.literals.list, imageSrc: listMenuItemIconGraphic },
        { label: dictionary.literals.map, imageSrc: mapMenuItemIconGraphic },
      ]}
      onChange={handleToggle}
    />
  );
};

export { HomeToggleBarTemplate };
