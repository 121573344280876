import { useNavigate } from 'react-router-dom';
import { FlexElement } from '../flex-element/flex-element.component';
import { HighlightableElement } from '../highlightable-element/highlightable-element.component';
import styles from './menu-items-element.module.css';
import { useSheetContext } from '../../../domain/sheet/sheet-context.hook';

type Props = {
  menuItems: {
    label: string;
    icon: string;
    path: string;
  }[];
};

const MenuItemElement = ({ menuItems }: Props): JSX.Element => {
  const { isShown, close: closeSheet } = useSheetContext();

  const navigate = useNavigate();

  const handleClickNavigate = (path: string) => {
    if (isShown) {
      closeSheet();
    }
    navigate(path, { replace: true });
  };

  return (
    <FlexElement column gap align="start" fullWidth>
      {menuItems.map((menuItem) => (
        <HighlightableElement
          key={menuItem.label}
          onClick={() => handleClickNavigate(menuItem.path)}
        >
          <FlexElement gap>
            <img
              src={menuItem.icon}
              alt={menuItem.label}
              className={styles.menuItemIcon}
            />
            <h5>{menuItem.label}</h5>
          </FlexElement>
        </HighlightableElement>
      ))}
    </FlexElement>
  );
};

export { MenuItemElement };
