import { ArrowHelper, Raycaster, Scene, Vector3 } from 'three';

const zeroVector = new Vector3(0, 0, 0);

const GizmosRay = (
  scene: Scene,
  length = 100,
): ((raycaster: Raycaster) => void) => {
  let didAddToScene = false;
  const arrow = new ArrowHelper(zeroVector, zeroVector, length);
  arrow.setColor(Math.random() * 0xffffff);
  return (raycaster: Raycaster) => {
    if (!didAddToScene) {
      scene.add(arrow);
      didAddToScene = true;
    }
    arrow.position.copy(raycaster.ray.origin);
    arrow.setDirection(raycaster.ray.direction);
  };
};

export { GizmosRay };
