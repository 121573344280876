import { Fragment } from 'react';
import { ActionButtonElement } from '../../components/elements/action-button-element/action-button-element.component';
import { useFloatingActionButtonContext } from './floating-action-button-context.hook';
import styles from './floating-action-button-renderer.module.css';

function FloatingActionButtonRenderer(): JSX.Element {
  const { isActive, options } = useFloatingActionButtonContext();

  if (isActive === false || options?.isHidden === true) {
    return <Fragment />;
  }

  return (
    <div className={styles.element}>
      <ActionButtonElement flavor={options?.flavor} onClick={options?.callback}>
        <img src={options?.imageSource} alt="" height={50} width={50} />
      </ActionButtonElement>
    </div>
  );
}

export { FloatingActionButtonRenderer };
