import { ISession } from '@hulanbv/toss';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class AuthenticationService extends CrudService<ISession> {
  constructor() {
    super([environment.apiUrl, 'authentication'].join('/'), httpService);
  }

  async validate(options?: IHttpOptions): Promise<IResponse<ISession>> {
    const response = await this.http.post<ISession>(
      [this.controller, 'validate'].join('/'),
      null,
      {
        ...options,
        populate: ['user', 'user.profileImage', ...(options?.populate ?? [])],
      },
    );
    return response;
  }

  async logout(options?: IHttpOptions): Promise<IResponse<void>> {
    const response = await this.http.post<void>(
      [this.controller, 'logout'].join('/'),
      null,
      options,
    );
    return response;
  }
}

const authenticationService = new AuthenticationService();

export { authenticationService };
