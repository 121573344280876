import { useImageZoomContext } from './image-zoom-context.hook';
import styles from './image-zoom-renderer.module.css';

function ImageZoomRenderer(): JSX.Element {
  const { imageRef, imageStyles, backgroundOpacity } = useImageZoomContext();

  return (
    <div
      className={styles.element}
      style={{
        background: `rgba(0, 0, 0, ${backgroundOpacity})`,
        opacity: imageRef ? 1 : 0,
      }}
    >
      {imageRef && <img src={imageRef?.src} style={imageStyles} alt="" />}
    </div>
  );
}

export { ImageZoomRenderer };
