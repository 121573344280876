import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IAreaNavigation, ISpot, SpatialDocument } from '@hulanbv/toss';
import {
  MapRenderer,
  MapRendererOptions,
  MapRendererType,
} from './map-renderer';
import styles from './map-renderer-element.module.css';
import { join } from '../../../domain/common/utilities/join.utility';

type Props = {
  options: MapRendererOptions;
  hasOwnBorderRadius?: boolean;
  showAreaDetails?: boolean;
};

const MapRendererElement = ({
  options,
  hasOwnBorderRadius,
  showAreaDetails,
}: Props): JSX.Element => {
  const mapRenderer = useRef<MapRendererType>(MapRenderer(options));
  const element = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();

  const floor: number | null = useMemo(() => {
    // If the name of the area starts with a number, it is a floor.
    if (options.area.name.match(/^\d/)) {
      return parseInt(options.area.name, 10);
    }
    return null;
  }, [options.area]);

  const handleMarkerClick = useCallback(
    (document: SpatialDocument) => {
      // setting the position in the search params, so that the position can
      // be used to set the coordinates for the user's location
      setSearchParams({
        position: document.coordinates.toString(),
        areaId: document.areaId,
      });

      if ('bannerId' in document) {
        const spot = document as ISpot;
        navigate(`/spot/${spot._id}`);
      } else if ('targetAreaNavigationId' in document) {
        const navigation = document as IAreaNavigation;
        navigate(`/map/navigation/${navigation.targetAreaNavigationId}`);
      } else if ('destination' in document) {
        const navigation = document as IAreaNavigation;
        navigate(`/map/navigation/${navigation.destination}`);
      }
    },
    [navigate, setSearchParams],
  );

  useEffect(() => {
    const currentMapRenderer = mapRenderer.current;
    currentMapRenderer?.onClickMarker.add(handleMarkerClick);
    return () => {
      currentMapRenderer?.onClickMarker.clear();
    };
  }, [handleMarkerClick]);

  useEffect(() => {
    const currentMapRenderer = mapRenderer.current;
    if (element.current) {
      currentMapRenderer?.mount(element.current);
    }
    return () => {
      currentMapRenderer?.unmount();
    };
  }, [element]);

  const className = join(
    styles.element,
    hasOwnBorderRadius && styles.borderRadius,
  );

  return (
    <div ref={element} className={className}>
      {showAreaDetails && (
        <div className={styles.areaDetails}>
          {floor !== null && (
            <div
              className={styles.floorIndicator}
              style={{ backgroundPositionX: floor * -100 }}
            />
          )}
          <h2>{options.area.name}</h2>
        </div>
      )}
    </div>
  );
};

export { MapRendererElement };
