import { ReactNode } from 'react';
import { FlexElement } from '../elements/flex-element/flex-element.component';

type Props = {
  title: string;
  description?: string;
  children: ReactNode;
};

const TitleContentTemplate = ({
  title,
  description,
  children,
}: Props): JSX.Element => (
  <FlexElement column gap={2} fullWidth verPadding={2} align="center">
    <h1>{title}</h1>
    <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
    {children}
  </FlexElement>
);

export { TitleContentTemplate };
