import { ILeaderboardItem } from '@hulanbv/toss';
import { useNavigate } from 'react-router-dom';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { FlexElement } from '../flex-element/flex-element.component';
import { HighlightableElement } from '../highlightable-element/highlightable-element.component';
import { ProfilePictureElement } from '../profile-picture-element/profile-picture-element.component';

type Props = {
  position: number;
  user: {
    id: string;
    username: string;
    profileImage: ILeaderboardItem['user']['profileImage'];
  };
  points: number;
};

const LeaderboardElement = ({ position, user, points }: Props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <HighlightableElement
      fullWidth
      onClick={() => navigate(`/profile/${user.id}`)}
    >
      <FlexElement gap fullWidth justify="flex-start">
        {position <= 3 ? <h2>{position}</h2> : <h3>{position}</h3>}
        <ProfilePictureElement
          profileImage={user.profileImage}
          size={position <= 3 ? 80 : 70}
        />
        <FlexElement column gap={0.5} align="flex-start">
          {position <= 3 ? <h2>{user.username}</h2> : <h3>{user.username}</h3>}
          <p>
            {points} {dictionary.literals.points}
          </p>
        </FlexElement>
      </FlexElement>
    </HighlightableElement>
  );
};

export { LeaderboardElement };
