import { BrowserRouter } from 'react-router-dom';
import { AuthenticationContext } from '../domain/authentication/authentication.context';
import { useAuthentication } from '../domain/authentication/authentication.hook';
import { FloatingActionButtonRenderer } from '../domain/floating-action-button/floating-action-button-renderer.component';
import { FloatingActionButtonContext } from '../domain/floating-action-button/floating-action-button.context';
import { useFloatingActionButton } from '../domain/floating-action-button/floating-action-button.hook';
import { ImageZoomRenderer } from '../domain/image-zoom/image-zoom-renderer.component';
import { ImageZoomContext } from '../domain/image-zoom/image-zoom.context';
import { useImageZoom } from '../domain/image-zoom/image-zoom.hook';
import { LoginBannerRenderer } from '../domain/login-banner/login-banner-renderer.component';
import { SheetRenderer } from '../domain/sheet/sheet-renderer.component';
import { SheetContext } from '../domain/sheet/sheet.context';
import { useSheet } from '../domain/sheet/sheet.hook';
import { TutorialContext } from '../domain/tutorial/tutorial.context';
import { useTutorial } from '../domain/tutorial/tutorial.hook';
import { Application } from './application.component';

// First entry point where the application is bootstrapped.
const Bootstrap = (): JSX.Element => {
  const authenticationHook = useAuthentication();
  const sheetHook = useSheet();
  const floatingActionButtonHook = useFloatingActionButton();
  const tutorialHook = useTutorial();
  const imageZoomHook = useImageZoom();

  return (
    <AuthenticationContext.Provider value={authenticationHook}>
      <SheetContext.Provider value={sheetHook}>
        <FloatingActionButtonContext.Provider value={floatingActionButtonHook}>
          <ImageZoomContext.Provider value={imageZoomHook}>
            <TutorialContext.Provider value={tutorialHook}>
              <BrowserRouter>
                <Application />
                <LoginBannerRenderer />
                <SheetRenderer />
                <FloatingActionButtonRenderer />
                <ImageZoomRenderer />
              </BrowserRouter>
            </TutorialContext.Provider>
          </ImageZoomContext.Provider>
        </FloatingActionButtonContext.Provider>
      </SheetContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export { Bootstrap };
