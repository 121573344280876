import { ISubmission } from '@hulanbv/toss';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class SubmissionService extends CrudService<ISubmission> {
  constructor() {
    super([environment.apiUrl, 'submissions'].join('/'), httpService);
  }

  getHighlightedSubmissions(
    options?: IHttpOptions<ISubmission[]>,
  ): Promise<IResponse<ISubmission[]>> {
    return this.http.get([this.controller, 'highlights'].join('/'), options);
  }
}

const submissionService = new SubmissionService();

export { submissionService };
