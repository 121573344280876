import { ChangeEvent } from 'react';
import styles from './input-text-element.module.css';

type Props = {
  name?: string;
  required?: boolean;
  autofocus?: boolean;
  placeholder?: string;
  type?: 'text' | 'number' | 'password' | 'email' | 'date';
  defaultValue?: string | number;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
};

const InputElement = ({
  name,
  required,
  autofocus,
  placeholder,
  type,
  defaultValue,
  isDisabled,
  onChange,
}: Props): JSX.Element => {
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <input
      className={styles.input}
      type={type || 'text'}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      autoFocus={autofocus}
      disabled={isDisabled}
      onChange={onChangeHandler}
    />
  );
};

export { InputElement };
