import { ISubmission, ISubmissionComment } from '@hulanbv/toss';
import { useCallback, useState } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import { MessageBubbleElement } from '../message-bubble-element/message-bubble-element.component';
import { CommentElement } from '../comment-element.component';
import { useAuthenticationContext } from '../../../domain/authentication/authentication-context.hook';
import { submissionCommentsService } from '../../../domain/submission-comments/submission-comments.service';
import { SubmissionElement } from '../submission-element/submission-element.component';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { usePagination } from '../../../domain/common/hooks/use-pagination.hook';
import { useOnBottomReached } from '../../../domain/common/hooks/use-on-bottom-reached.hook';
import { SpinnerElement } from '../spinner-element/spinner-element.component';
import { HighlightableElement } from '../highlightable-element/highlightable-element.component';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './view-submission-element.module.css';
import { useContextAwareNavigation } from '../../../domain/common/hooks/use-context-aware-navigation.hook';

type Props = {
  submission: ISubmission;
  shouldShowFullSubmission?: boolean;
  isHighlighted?: boolean;
};

const ViewSubmissionElement = ({
  submission,
  shouldShowFullSubmission,
  isHighlighted,
}: Props): JSX.Element => {
  const { navigate: navigateToSubmission } = useContextAwareNavigation(
    `/submission/${submission._id}`,
  );
  const { session } = useAuthenticationContext();

  const paginateComments = useCallback(
    async (skip: number, limit: number) => {
      if (shouldShowFullSubmission !== true) {
        return [];
      }

      const { data: submissions } = await submissionCommentsService.getAll({
        match: { submissionId: submission._id },
        sort: ['-createdAt'],
        populate: ['author', 'author.profileImage'],
        offset: skip,
        limit,
      });

      return submissions;
    },
    [shouldShowFullSubmission, submission._id],
  );
  const {
    items: comments,
    nextPage,
    reset: resetPagination,
    isLoading: isLoadingComments,
  } = usePagination(paginateComments, 10);
  useOnBottomReached(shouldShowFullSubmission ? nextPage : () => undefined);

  const [sentComment, setSentComment] = useState<ISubmissionComment | null>(
    null,
  );

  const [isAnimating, setIsAnimating] = useState(false);

  const handleSubmitCommentForm = useCallback(
    async (message: string) => {
      setIsAnimating(true);
      setSentComment({
        message,
        author: session?.user,
        authorId: session?.user?.id,
        submissionId: submission.id,
        createdAt: new Date(),
      });
      try {
        await submissionCommentsService.post({
          submissionId: submission.id,
          authorId: session?.user?.id,
          message,
        });
      } finally {
        window.setTimeout(async () => {
          setIsAnimating(false);
          await resetPagination();
          setSentComment(null);
        }, 500);
      }
    },
    [resetPagination, session?.user, submission.id],
  );

  return (
    <FlexElement
      attributes={{ className: join(isHighlighted && styles.isHighlighted) }}
      column
      gap
      align="start"
    >
      <SubmissionElement
        submission={submission}
        isSubmissionExtended={shouldShowFullSubmission}
        isHighlighted={isHighlighted}
      />
      {shouldShowFullSubmission && (
        <>
          <FlexElement fullWidth>
            <CommentElement
              key={sentComment?.message}
              onSubmit={handleSubmitCommentForm}
            />
          </FlexElement>
          <FlexElement column align="start" gap={0.5} horPadding={0.5}>
            {[...(sentComment ? [sentComment] : []), ...comments].map(
              (comment, index) => (
                <MessageBubbleElement
                  key={index}
                  author={comment.author}
                  date={comment.createdAt}
                  shouldAnimate={index === 0 ? isAnimating : false}
                >
                  {comment.message}
                </MessageBubbleElement>
              ),
            )}
          </FlexElement>
          {isLoadingComments === true && (
            <FlexElement fullWidth>
              <SpinnerElement />
            </FlexElement>
          )}
        </>
      )}
      {!shouldShowFullSubmission && submission.commentsCount > 0 && (
        <FlexElement column align="start" gap={0.5} horPadding={0.5}>
          {submission.comments?.slice(0, 2).map((comment) => (
            <MessageBubbleElement
              key={comment.id}
              author={comment.author}
              date={comment.createdAt}
              shouldClampText
            >
              {comment.message}
            </MessageBubbleElement>
          ))}
          {submission.commentsCount > 2 && (
            <HighlightableElement onClick={navigateToSubmission}>
              <h6>{dictionary.literals.andMoreComments}</h6>
            </HighlightableElement>
          )}
        </FlexElement>
      )}
    </FlexElement>
  );
};

export { ViewSubmissionElement };
