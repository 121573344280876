import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';

const AboutUsScreen = (): JSX.Element => (
  <ScreenContainerElement title={dictionary.literals.aboutToss}>
    <FlexElement fullWidth column gap align="flex-start">
      <p>
        The Other Side Space (TOSS) is a BOOST! Pilot project that aims to
        develop a gamified digital twin of the Vertigo Building. TOSS provides a
        virtual and spatial platform for sharing and showcasing the research and
        education of the Built Environment Department of Eindhoven University of
        Technology. TOSS incorporates gamified mechanisms to reward more
        physical movement and social interactions in the building.
      </p>
      <p>
        TOSS aims to provide a double connection between the virtual and
        physical Vertigo. You can scan TOSS QR codes across the building to
        visit the virtual pages of each spot and learn a bit more about the
        research and education that happens in those spaces. At its current
        stage, TOSS includes the representation of the Vertigo Lobby and the 7th
        floor. In future phases, we plan to expand TOSS to the rest of the
        Vertigo building as well.
      </p>
      <p>
        TOSS is publicly available to browse for everyone. You can share the
        links to its virtual spots to show case a portfolio or to specific posts
        in it. Moreover, students and employees of the Built Environment
        department can login to TOSS using their TU/e account and post new
        content as well as interacting with existing content.
      </p>
      <p>
        We see immense potentials in expanding TOSS to all floors of Vertigo and
        the rest of the buildings. We are eager to hear your opinion and
        suggestions at <a href="mailto:toss@tue.nl">toss@tue.nl</a>.
      </p>
    </FlexElement>
  </ScreenContainerElement>
);

export { AboutUsScreen };
