import { IAsset, ISubmission } from '@hulanbv/toss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { assetService } from '../../domain/asset/asset.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { SelectFileElement } from '../elements/select-file-element/select-file-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ContainerElement } from '../elements/container-element/container-element.component';
import { InputElement } from '../elements/input-text-element/input-text-element.component';
import { UserDataTextareaTemplate } from './user-data-textarea-template.component';

type Props = {
  submission?: Partial<ISubmission>;
  onSubmit: (formdata: FormData) => void | Promise<void>;
};

const SubmissionFormTemplate = ({
  submission,
  onSubmit,
}: Props): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [preUploadedFiles, setPreUploadedFiles] = useState<IAsset[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleClickSubmit = useCallback(async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setIsUploading(true);

    const formData = new FormData(formRef.current);
    const fileUploadPromises = selectedFiles.map(async (file) => {
      const assetFormData = new FormData();
      assetFormData.append('file', file);
      const { data: asset } = await assetService.post(assetFormData);
      setUploadedFiles((uploadedFiles) => [...uploadedFiles, file]);
      formData.append('assetIds[]', asset.id);
    });
    await Promise.all(fileUploadPromises);

    preUploadedFiles.forEach((file) => {
      formData.append('assetIds[]', file.id);
    });

    onSubmit(formData);
  }, [onSubmit, preUploadedFiles, selectedFiles]);

  useEffect(() => {
    if (submission?.assets) {
      setPreUploadedFiles(submission.assets);
    }
  }, [submission?.assets]);

  return (
    <form ref={formRef} style={{ width: '100%' }}>
      <FlexElement column gap={2}>
        <ContainerElement flavour="primary">
          <FlexElement
            column
            gap
            padding={
              selectedFiles.length > 0 || preUploadedFiles.length > 0 ? 1 : 0
            }
            fullWidth
            minHeight={250}
          >
            <SelectFileElement
              isUploading={isUploading}
              uploadedFiles={uploadedFiles}
              preUploadedFiles={preUploadedFiles}
              onChangeUploadedFiles={(files: File[]) => setSelectedFiles(files)}
              onChangePreUploadedFiles={(files: IAsset[]) =>
                setPreUploadedFiles(files)
              }
            />
            {(selectedFiles.length > 0 || preUploadedFiles.length > 0) && (
              <>
                <InputElement
                  placeholder={`${dictionary.literals.titleYourWork}...`}
                  name="title"
                  defaultValue={submission?.title}
                  isDisabled={isUploading}
                  autofocus
                  required
                />
                <UserDataTextareaTemplate
                  defaultValue={submission?.description}
                  name="description"
                  backgroundFlavour="secondary"
                  isMultiline
                  isDisabled={isUploading}
                  placeholder={dictionary.texts.descriptionPlaceholder}
                  required
                />
              </>
            )}
          </FlexElement>
        </ContainerElement>
        <ActionButtonElement
          onClick={handleClickSubmit}
          flavor="primaryLightScheme-rounded"
          isDisabled={
            (selectedFiles.length === 0 && preUploadedFiles.length === 0) ||
            isUploading
          }
        >
          <h3>{dictionary.literals.publishContent}</h3>
        </ActionButtonElement>
      </FlexElement>
    </form>
  );
};

export { SubmissionFormTemplate };
