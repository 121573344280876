import { useContext } from 'react';
import { SheetContext } from './sheet.context';
import { useSheet } from './sheet.hook';

// Wraps the SheetContext in a hook.
function useSheetContext(): ReturnType<typeof useSheet> {
  const context = useContext(SheetContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useSheetContext };
