import { useMemo } from 'react';
import { useAuthenticationContext } from '../../../domain/authentication/authentication-context.hook';
import { FlexElement } from '../flex-element/flex-element.component';
import { ProfilePictureElement } from '../profile-picture-element/profile-picture-element.component';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { useTutorialContext } from '../../../domain/tutorial/tutorial-context.hook';
import { MenuItemElement } from '../menu-items-element/menu-items-element.component';
import homeMenuItemIconGraphic from '../../../assets/graphics/home-menu-item-icon.svg';
import profileMenuItemIconGraphic from '../../../assets/graphics/profile-menu-item-icon.svg';
import mapMenuItemIconGraphic from '../../../assets/graphics/map-menu-item-icon.svg';
import aboutTossIconGraphic from '../../../assets/graphics/info-icon.svg';
import privacyStatementIconGraphic from '../../../assets/graphics/privacy-statement-icon.svg';
import styles from './menubar-element.module.css';

const MenubarElement = (): JSX.Element => {
  const { isAuthenticated, session } = useAuthenticationContext();
  const { hasSeenTutorial } = useTutorialContext();

  const menuItems = useMemo(() => {
    const menuItems = [
      {
        label: dictionary.literals.feed,
        icon: homeMenuItemIconGraphic,
        path: '/',
      },
      {
        label: dictionary.literals.explore,
        icon: mapMenuItemIconGraphic,
        path: '/map',
      },
      {
        label: dictionary.literals.aboutToss,
        icon: aboutTossIconGraphic,
        path: '/about',
      },
      {
        label: dictionary.literals.privacyStatement,
        icon: privacyStatementIconGraphic,
        path: '/privacy-statement',
      },
    ];
    if (isAuthenticated === true) {
      menuItems.push({
        label: session?.user?.firstName ?? 'Profile',
        icon: profileMenuItemIconGraphic,
        path: `/profile/${session?.userId}`,
      });
    }
    return menuItems;
  }, [session, isAuthenticated]);

  if (hasSeenTutorial === false) {
    return <></>;
  }

  return (
    <div className={styles.element}>
      <FlexElement column gap={2}>
        {isAuthenticated === true && (
          <FlexElement fullWidth gap justify="start">
            <ProfilePictureElement
              profileImage={session?.user?.profileImage}
              size={50}
            />
            <FlexElement column align="start">
              <p>{dictionary.literals.welcomeBack},</p>
              <h2>{`${session?.user?.firstName} ${session?.user?.lastName}`}</h2>
            </FlexElement>
          </FlexElement>
        )}
        <MenuItemElement menuItems={menuItems} />
      </FlexElement>
    </div>
  );
};

export { MenubarElement };
