import { KeyboardEvent, ChangeEvent, useState, useRef } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './input-text-area-element.module.css';

type Props = {
  defaultValue?: string;
  name?: string;
  placeholder?: string;
  isMultiline?: boolean;
  isDisabled?: boolean;
  isWithUserData?: boolean;
  required?: boolean;
  handleKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  handleTextChange?: (text: string) => void;
};

const InputTextAreaElement = ({
  defaultValue,
  name,
  placeholder,
  isMultiline,
  required,
  isDisabled = false,
  isWithUserData = false,
  handleKeyDown,
  handleTextChange,
}: Props): JSX.Element => {
  const [value, setValue] = useState(defaultValue ?? '');
  const textareaRefElement = useRef<HTMLTextAreaElement>(null);
  const onTextInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let { value } = event.target;
    if (textareaRefElement?.current && isMultiline !== true) {
      value = event.target.value
        .replace(/(^\s*)|(\n)/g, '')
        .replace(/ +$/g, ' ');
      const textareaElement = textareaRefElement.current;
      textareaElement.value = value;
    }
    setValue(value);
    handleTextChange?.(value);
  };

  return (
    <div
      className={join(
        isWithUserData === false && styles.spacing,
        styles.textAreaWrapper,
      )}
      data-replicated-value={value}
    >
      <textarea
        ref={textareaRefElement}
        className={isWithUserData === false ? styles.spacing : undefined}
        name={name}
        maxLength={500}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={onTextInputChange}
        onKeyDown={handleKeyDown}
        required={required}
      />
      <div className={styles.textAreaCounter}>{value.trim().length}/500</div>
    </div>
  );
};

export { InputTextAreaElement };
