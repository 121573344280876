import { IUser } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class UserService extends CrudService<IUser> {
  constructor() {
    super([environment.apiUrl, 'users'].join('/'), httpService);
  }
}

const userService = new UserService();

export { userService };
