import { useContext } from 'react';
import { ImageZoomContext } from './image-zoom.context';
import { useImageZoom } from './image-zoom.hook';

// Wraps the SheetContext in a hook.
function useImageZoomContext(): ReturnType<typeof useImageZoom> {
  const context = useContext(ImageZoomContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useImageZoomContext };
