import { ReactNode } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import { ActionButtonElementFlavor } from './action-button-element-flavor.type';
import styles from './action-button-element.module.css';

type Props = {
  children: ReactNode;
  onClick?: () => void | Promise<void>;
  flavor?: ActionButtonElementFlavor;
  fullWidth?: boolean;
  isUsingColorScheme?: boolean;
  isDisabled?: boolean;
};

const ActionButtonElement = ({
  children,
  onClick,
  isDisabled = false,
  fullWidth = false,
  flavor = 'primary-rounded',
}: Props): JSX.Element => (
  <div
    onClick={onClick}
    className={join(
      styles.element,
      fullWidth === true && styles.fullWidth,
      isDisabled === true && styles.disabled,
      ...flavor.split('-').map((slice) => styles[slice]),
    )}
  >
    {children}
  </div>
);

export { ActionButtonElement };
