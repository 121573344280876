import { ReactNode, useCallback, useState } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './card-element.module.css';

type Props = {
  children: ReactNode;
  flavour: 'primary' | 'secondary';
  clickable?: boolean;
};

const CardElement = ({
  children,
  flavour,
  clickable = false,
}: Props): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  // handles the click event of the card
  const handleCardClick = useCallback(() => {
    if (clickable !== true) {
      return;
    }

    setIsActive(true);
  }, [clickable]);

  // handles the blur event of the card
  const handleOnBlur = useCallback(() => {
    if (clickable !== true) {
      return;
    }

    setIsActive(false);
  }, [clickable]);

  return (
    <div
      className={join(
        styles.card,
        clickable === true && styles.clickable,
        isActive && styles.onFocus,
        flavour === 'primary' && styles.primary,
        flavour === 'secondary' && styles.secondary,
      )}
      onClick={handleCardClick}
      onBlur={handleOnBlur}
    >
      {children}
    </div>
  );
};

export { CardElement };
