import { dictionary } from '../constants/dictionary.constants';

const dateToLocale = (date?: string | Date): string => {
  if (!date) {
    return dictionary.literals.someTimeAgo;
  }
  const dateObject = new Date(date ?? '');
  const timeAgo = new Date().getTime() - dateObject.getTime();
  if (timeAgo < 60000) {
    return dictionary.literals.justNow;
  }
  if (timeAgo < 3600000) {
    return `${Math.floor(timeAgo / 60000)} ${dictionary.literals.minutesAgo}`;
  }
  if (timeAgo < 86400000) {
    return `${Math.floor(timeAgo / 3600000)} ${dictionary.literals.hoursAgo}`;
  }
  return `${Math.floor(timeAgo / 86400000)} ${dictionary.literals.daysAgo}`;
};

export { dateToLocale };
