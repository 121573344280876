import { ReactNode } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './container-element.module.css';

type Props = {
  children: ReactNode;
  flavour?: 'primary' | 'secondary';
};

const ContainerElement = ({ children, flavour }: Props): JSX.Element => (
  <div
    className={join(
      styles.container,
      flavour === 'primary' && styles.primary,
      flavour === 'secondary' && styles.secondary,
    )}
  >
    {children}
  </div>
);

export { ContainerElement };
