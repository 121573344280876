import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { CardElement } from '../elements/card-element/card-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { InputTextAreaElement } from '../elements/input-text-area-element/input-text-area-element.component';

type Props = {
  title: string;
  biography?: string;
  onSubmit: (formdata: FormData) => void | Promise<void>;
};

const EditProfileFormTemplate = ({
  title,
  biography,
  onSubmit,
}: Props): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const { logout } = useAuthenticationContext();
  const { close: closeSheet } = useSheetContext();
  const navigate = useNavigate();

  const handleClickSubmit = useCallback(() => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      onSubmit(formData);
    }
  }, [onSubmit]);

  const handleClickLogout = useCallback(() => {
    // logout and redirect to home
    logout();
    closeSheet();
    navigate('/');
  }, [logout, closeSheet, navigate]);

  return (
    <FlexElement column gap={2} fullWidth verPadding={2} align="center">
      <form ref={formRef} style={{ width: '100%' }}>
        <FlexElement column gap={2} align="center">
          <h1>{title}</h1>
          <CardElement flavour="primary">
            <InputTextAreaElement
              defaultValue={biography}
              name="bio"
              placeholder={dictionary.literals.writeSomethingAboutYourself}
            />
          </CardElement>
        </FlexElement>
      </form>
      <FlexElement column gap={0.5} fullWidth>
        <ActionButtonElement
          onClick={handleClickSubmit}
          fullWidth
          flavor="primaryLightScheme-rounded"
        >
          <h3>{dictionary.literals.saveChanges}</h3>
        </ActionButtonElement>
        <ActionButtonElement
          onClick={handleClickLogout}
          fullWidth
          flavor="light-rounded"
        >
          <h3>{dictionary.literals.logout}</h3>
        </ActionButtonElement>
      </FlexElement>
    </FlexElement>
  );
};

export { EditProfileFormTemplate };
