import { useState } from 'react';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { environment } from '../../domain/environment/environment.constants';
import { ActionButtonElement } from '../elements/action-button-element/action-button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { Checkbox } from '../elements/checkbox-element/checkbox-element.component';

type Props = {
  emoji: string;
};

const LoginWallTemplate = ({ emoji }: Props): JSX.Element => {
  const [isAbleToLogin, setIsAbleToLogin] = useState(false);

  const handleClickLogin = () => {
    const href = [environment.apiUrl, 'authentication', 'sso', 'login'].join(
      '/',
    );
    window.location.href = href;
  };

  return (
    <FlexElement column gap={2} fullWidth verPadding={2} align="center">
      <div style={{ fontSize: '4rem' }}>{emoji}</div>
      <h1>{dictionary.texts.onboardingTitle}</h1>

      <p>{dictionary.texts.onboardingTextLong}</p>
      <Checkbox
        label={dictionary.texts.consentForLoggingIn}
        attributes={{
          onChange: setIsAbleToLogin,
        }}
      />
      <ActionButtonElement
        onClick={handleClickLogin}
        flavor="primaryLightScheme-rounded"
        isDisabled={!isAbleToLogin}
      >
        <h3>{dictionary.literals.login}</h3>
      </ActionButtonElement>
    </FlexElement>
  );
};

export { LoginWallTemplate };
