import { IAreaNavigation } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class AreaNavigationService extends CrudService<IAreaNavigation> {
  constructor() {
    super([environment.apiUrl, 'area-navigations'].join('/'), httpService);
  }
}

const areaNavigationService = new AreaNavigationService();

export { areaNavigationService };
