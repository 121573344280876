import { useCallback, useMemo, useState } from 'react';

function useLocalStorage<ValueType>(
  key: string,
  intialValue?: ValueType,
): [value: ValueType | undefined, setValue: (value?: ValueType) => void] {
  const storedValue = useMemo(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return undefined;
    }
    return JSON.parse(storedValue);
  }, [key]);
  const [stateValue, setStateValue] = useState<ValueType | undefined>(
    storedValue ?? intialValue,
  );

  const setValue = useCallback(
    (value?: ValueType) => {
      if (value === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
      setStateValue(value);
    },
    [key],
  );

  return [stateValue, setValue];
}

export { useLocalStorage };
