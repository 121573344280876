import { Fragment } from 'react';
import { useSheetContext } from './sheet-context.hook';
import styles from './sheet-renderer.module.css';

function SheetRenderer(): JSX.Element {
  const { isShown, children, close } = useSheetContext();

  if (isShown === false) {
    return <Fragment />;
  }
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.closeButton} onClick={close} />
          {children}
        </div>
      </div>
    </div>
  );
}

export { SheetRenderer };
