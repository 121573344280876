import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import { ImageIndicatorElement } from '../image-indicator-element/image-indicator.component';
import { ImageSlideContent } from '../image-slide-content.component';
import styles from './image-carousel.module.css';

type Props = {
  slides: string[];
};

const ImageCarouselElement = ({ slides }: Props): JSX.Element => {
  const carouselImageElements = useRef<HTMLImageElement[]>(
    new Array(slides.length),
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentSlideIndex(
              carouselImageElements.current.findIndex(
                (element) => element === entry.target,
              ),
            );
          }
        });
      },
      { threshold: 0.7 },
    );
    carouselImageElements.current.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  const handleOnClick = useCallback((index: number) => {
    carouselImageElements.current[index]?.scrollIntoView();
  }, []);

  return (
    <FlexElement fullWidth gap column>
      <div className={styles.imageContainer}>
        {slides.map((image, index) => (
          <div className={styles.imageWrapper} key={index}>
            <img
              ref={(element) => {
                if (element) {
                  carouselImageElements.current[index] = element;
                }
              }}
              src={image}
              className={styles.image}
              alt={`photograph${index}`}
            />
          </div>
        ))}
      </div>
      <ImageIndicatorElement
        totalSlides={slides.length}
        currentSlideIndex={currentSlideIndex}
        handleOnClick={handleOnClick}
      />
      <ImageSlideContent currentSlideIndex={currentSlideIndex} />
    </FlexElement>
  );
};

export { ImageCarouselElement };
