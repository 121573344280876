/**
 * Hook to handle the navigation inside the application. This hook is a copy of
 * the useNavigate hook from react-router-dom, but with the added functionality
 * to prevent the navigation if the current path is the same as the path to
 * navigate.
 */

import { useCallback, useMemo } from 'react';
// eslint-disable-next-line import/named -- This is partly a type import.
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

function useContextAwareNavigation(
  path: To,
  options?: NavigateOptions,
): {
  /**
   * If true, the current path is the same as the path to navigate. If false,
   * the current path is different from the path to navigate.
   */
  isCurrentPath: boolean;
  /**
   * Navigate to the given path. If the current path is the same as the path to
   * navigate, the navigation is prevented.
   */
  navigate: () => void;
} {
  const reactRouterNavigate = useNavigate();

  const isCurrentPath = useMemo(
    () => window.location.pathname === path,
    [path],
  );

  const navigate = useCallback(() => {
    if (isCurrentPath === true) {
      return;
    }

    reactRouterNavigate(path, options);
  }, [isCurrentPath, options, path, reactRouterNavigate]);

  return { isCurrentPath, navigate };
}

export { useContextAwareNavigation };
