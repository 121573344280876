import { ISubmissionComment } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class SubmissionCommentsService extends CrudService<ISubmissionComment> {
  constructor() {
    super([environment.apiUrl, 'submission-comments'].join('/'), httpService);
  }
}

const submissionCommentsService = new SubmissionCommentsService();

export { submissionCommentsService };
