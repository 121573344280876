import { ReactNode, useCallback, useState } from 'react';

type ISheetHook = {
  isShown: boolean;
  children: ReactNode;
  show: (children: ReactNode) => void;
  close: () => void;
};

function useSheet(): ISheetHook {
  const [isShown, setIsShown] = useState(false);
  const [children, setChildren] = useState<ReactNode>(null);

  const show = useCallback((children: ReactNode) => {
    // TODO -- Scroll-lock the body.
    setIsShown(true);
    setChildren(children);
  }, []);

  const close = useCallback(() => {
    // TODO -- Unlock the body.
    setIsShown(false);
    setChildren(null);
  }, []);

  return {
    isShown,
    show,
    close,
    children,
  };
}

export { useSheet };
