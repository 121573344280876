import { IUser } from '@hulanbv/toss';
import { useState } from 'react';
import { useContextAwareNavigation } from '../../../domain/common/hooks/use-context-aware-navigation.hook';
import { dateToLocale } from '../../../domain/common/utilities/date-to-locale.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import { ProfilePictureElement } from '../profile-picture-element/profile-picture-element.component';
import styles from './message-bubble-element.module.css';

const clampTextThreshold = 100;

type Props = {
  children: string;
  author?: IUser;
  date?: Date | string;
  shouldClampText?: boolean;
  shouldAnimate?: boolean;
};

const MessageBubbleElement = ({
  children,
  author,
  shouldClampText,
  shouldAnimate,
  date,
}: Props): JSX.Element => {
  const { navigate: navigateToProfile } = useContextAwareNavigation(
    `/profile/${author?._id}`,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClickReadMore = () => setIsExpanded(true);
  const isTextClamped =
    shouldClampText === true &&
    isExpanded === false &&
    children.length > clampTextThreshold;

  return (
    <div
      className={styles.element}
      data-animation={shouldAnimate && 'slide-in-bottom'}
    >
      <FlexElement gap={0.5} padding={0.5} align="start">
        {author && (
          <div
            style={{ flexShrink: 0, cursor: 'pointer' }}
            onClick={navigateToProfile}
          >
            <ProfilePictureElement
              profileImage={author.profileImage}
              size={20}
            />
          </div>
        )}
        <FlexElement column align="start" gap={0.25}>
          <p>
            <strong onClick={navigateToProfile}>
              {author?.firstName} {author?.lastName}{' '}
            </strong>
            {isTextClamped ? (
              <>
                {`${children.slice(0, clampTextThreshold).trim()}... `}
                <b onClick={handleClickReadMore}>Read more</b>
              </>
            ) : (
              children
            )}
          </p>
          {date && <h6>{dateToLocale(date)}</h6>}
        </FlexElement>
      </FlexElement>
    </div>
  );
};

export { MessageBubbleElement };
