import { IAsset } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class AssetService extends CrudService<IAsset> {
  constructor() {
    super([environment.apiUrl, 'assets'].join('/'), httpService);
  }
}

const assetService = new AssetService();

export { assetService };
