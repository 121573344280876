import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';

const PrivacyStatementScreen = (): JSX.Element => (
  <ScreenContainerElement title={dictionary.literals.privacyStatement}>
    <FlexElement fullWidth column gap align="flex-start">
      <h2>
        How does Eindhoven University of Technology process personal data in
        relation to The Other Side Space (TOSS) platform
      </h2>
      <h5>1. Introduction</h5>
      <p>
        In this Privacy Statement we provide you with information concerning the
        collection of your personal data by TU/e in relation to TOSS platform.
      </p>
      <p>
        We have tried to provide all information to you in clear and plain
        language. If you have any questions concerning our use of your personal
        data after reading this Privacy Statement, you may contact us via the
        address data at the foot of the Privacy Statement.
      </p>
      <p>This Privacy Statement is valid since 1 January 2023.</p>

      <h5>2. Who is responsible for your personal data?</h5>
      <p>
        TU/e is Controller as described in the General Data Protection
        Regulation (GDPR), for the processings that are mentioned in this
        Privacy Statement. This means that TU/e is responsible for a careful and
        proper processing of your personal data.
      </p>

      <h5>3. What personal data do we process?</h5>
      <p>
        In relation to TOSS platform we process among other the following
        personal data:
      </p>
      <ul>
        <li>Identification Data: name, e-mail, organizational position</li>
        <li>
          Content Data: Documents, images, and videos that users share on the
          platform
        </li>
        <li>
          Location Data: Presence in room, that users voluntarily share through
          scanning QR codes
        </li>
        <li>
          Interaction Data: Likes and comments that users leave under the
          contents
        </li>
      </ul>

      <h5>4. For what purposes do we process your personal data?</h5>
      <p>
        The personal data are processed in relation to TOSS platform for the
        following purposes:
      </p>
      <ol>
        <li>Facilitate the social interaction of the students and employees</li>
        <li>
          Promote a healthy and active life-style through more physical movement
          in the building
        </li>
        <li>
          Provide for faculty and student outreach by creating an online and
          spatial portfolio of the whole department
        </li>
        <li>
          Create a platform for further research on spatial behavior and spatial
          perception
        </li>
      </ol>

      <h5>5. What is the basis for processing your personal data?</h5>
      <p>
        To be permitted to process your personal data, the processing must be
        based on one of the legal grounds of the GDPR. In the case of TU/e, that
        is for all of the above-mentioned purposes, informed consent is the
        basis.
      </p>

      <h5>6. Who will have access to your personal data?</h5>
      <h6>6.1 Access to your personal data within the TU/e</h6>
      <p>
        All relevant employees who are involved with TOSS platform will have
        access to your personal data, but only as far as is required to fulfil
        their respective tasks. These employees are researchers.
      </p>
      <p>
        Apart from those mentioned above, only authorized persons in relevant
        sections of the TU/e like IT, Legal, Compliance or the Counselor, but
        only as far as is required to fulfil their respective tasks.
      </p>

      <h6>6.2 Access to your personal data by third parties</h6>
      <p>
        The following categories of third parties have access to or will obtain
        your personal data, where this is relevant for the facilities and
        services of these parties towards TU/e
      </p>
      <ul>
        <li>IT suppliers to Hulan B.V.: Digital Ocean (the Netherlands)</li>
      </ul>
      <p>
        We will not share the data we process in relation to TOSS platform with
        third parties outside the EU/EEA, unless this is a legal obligation.
      </p>

      <h6>6.3 Use of your personal data by Processors</h6>
      <p>
        If a third party processes your personal data on our instructions, then
        this party is a Processor. Our Processors are: Hulan B.V. (the
        Netherlands). With such Processors we make agreements concerning the
        processing of your personal data. Such an agreement will in any case
        stipulate that certain obligations concerning protection of personal
        data are respected, thus ensuring that data are processed with due
        regard for the wishes and standards of TU/e.
      </p>

      <h5>7. For how long will your personal data be kept?</h5>
      <p>
        The TU/e will keep your personal data in accordance with the GDPR. The
        data are not kept for longer than is necessary to reach the goals for
        which the data have been collected, and will be erased when you withdraw
        your consent and there is no other basis to process your data lawfully.
      </p>

      <h5>
        8. Will my data be processed outside the European Economic Area ('EEA')?
      </h5>
      <p>
        TU/e endeavors to process your data only within the European Economic
        Area ("EEA") by storing your data on a server in the EEA wherever
        possible. If we engage processors, we require them to store personal
        data on servers in the EEA.
      </p>

      <h5>9. How are your personal data protected?</h5>
      <p>
        TU/e has implemented appropriate technical and organizational measures
        to protect the personal data against unintended or unlawful destruction
        of the data, unintended damage, loss, alteration or unauthorized
        disclosure or access, and against all other forms of unlawful processing
        (including, but not limited to, unnecessary collection of data) or
        further processing. These appropriate technical and organizational
        measures include limitation of access to data through authorization and
        authentication; guidelines within the organization concerning the
        processing of personal data; password encryption with the blowfish
        algorithm; database positioned on separate server; use of encrypted
        environment variables for serverless applications; generic aggregation
        structure; and development, acceptance and production environments.
      </p>

      <h5>10. How can you exercise your privacy rights?</h5>
      <p>
        You have the right of access to your personal data and, under certain
        conditions, rectification and/or erasure of your personal data.
        Furthermore, in certain cases you also have the right to restriction of
        processing of your personal data, the right to object to the processing
        and the right to data portability.
      </p>
      <p>
        To exercise your right of access, rectification, erasure, restriction,
        objection or data portability, please contact us via the address data at
        the foot of this Privacy Statement. Note however that we may ask for
        additional information to verify your identity when exercising these
        rights.
      </p>
      <p>
        If you have given consent to process your personal data for a certain
        purpose, you may always withdraw this consent. Note however that
        withdrawal of your consent is not retroactive. You can remove your data
        in your account on TOSS and deregister. You can also contact us
        regarding this at s.azadi@tue.nl.
      </p>

      <h5>11. Whom can you contact?</h5>
      <p>
        If you have questions about how we process your personal data, please
        let us know via <a href="mailto:privacy@tue.nl">privacy@tue.nl</a>. We
        will be happy to assist you.
      </p>
      <p>
        If you believe that the processing of your personal data is not in line
        with the GDPR, for example if you believe we are not processing your
        personal data with due care, or if your request for access or
        rectification has not been answered in time, you may lodge a complaint
        with the Data Protection Officer (hereafter: DPO) via{' '}
        <a
          href="https://www.dataprotectionofficer@tue.nl"
          target="_blank"
          rel="noreferrer"
        >
          dataprotectionofficer@tue.nl
        </a>
        . The DPO serves as the link between the TU/e and the Dutch Data
        Protection Authority. The DPO acts independently and can discuss your
        complaint or ask for advice from the Data Protection Authority.
      </p>
      <p>
        If you do not agree with the handling of your complaint by the DPO, you
        may lodge a complaint directly to the Data Protection Authority. The
        Data Protection Authority will handle the complaint or the request and
        decide upon it.
      </p>
    </FlexElement>
  </ScreenContainerElement>
);

export { PrivacyStatementScreen };
