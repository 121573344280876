import { useContext } from 'react';
import { TutorialContext } from './tutorial.context';
import { useTutorial } from './tutorial.hook';

// Wraps the SheetContext in a hook.
function useTutorialContext(): ReturnType<typeof useTutorial> {
  const context = useContext(TutorialContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useTutorialContext };
