import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './togglebar-element.module.css';

type Props = {
  options: {
    label: string;
    imageSrc?: string;
  }[];
  activeIndex: number;
  onChange?: (index: number) => void;
};

const TogglebarElement = ({
  options,
  activeIndex,
  onChange,
}: Props): JSX.Element => (
  <FlexElement>
    <div className={styles.element}>
      <FlexElement gap={1} horPadding={1.5} verPadding={0.5}>
        {options.map((option, index) => (
          <img
            key={index}
            onClick={() => index !== activeIndex && onChange?.(index)}
            src={option.imageSrc}
            alt={option.label}
            className={join(
              styles.option,
              index === activeIndex && styles.activeOption,
            )}
          />
        ))}
      </FlexElement>
    </div>
  </FlexElement>
);

export { TogglebarElement };
