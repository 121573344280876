import { ISpot } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class SpotService extends CrudService<ISpot> {
  constructor() {
    super([environment.apiUrl, 'spots'].join('/'), httpService);
  }
}

const spotService = new SpotService();

export { spotService };
