import { ILeaderboardItem } from '@hulanbv/toss';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { FlexElement } from '../flex-element/flex-element.component';
import { ProfilePictureElement } from '../profile-picture-element/profile-picture-element.component';
import styles from './featured-users-element.module.css';

type Props = {
  items: ILeaderboardItem[];
};

const FeaturedUsersElement = ({ items }: Props): JSX.Element => (
  <FlexElement>
    <FlexElement fullWidth maxWidth={400} align="start" isRelative>
      {items.map((item) => (
        <FlexElement
          key={item.user.id}
          attributes={{ className: styles.item }}
          column
          gap
        >
          <ProfilePictureElement
            profileImage={item.user.profileImage}
            size={'100%'}
          />
          <FlexElement fullWidth column gap={0.5}>
            <h5>{item.user.username}</h5>
            <h6>
              {item.score} {dictionary.literals.points}
            </h6>
          </FlexElement>
        </FlexElement>
      ))}
    </FlexElement>
  </FlexElement>
);

export { FeaturedUsersElement };
