import { ISubmissionLike } from '@hulanbv/toss';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class SubmissionLikesService extends CrudService<ISubmissionLike> {
  constructor() {
    super([environment.apiUrl, 'submission-likes'].join('/'), httpService);
  }
}

const submissionLikesService = new SubmissionLikesService();

export { submissionLikesService };
