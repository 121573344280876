import { dateToLocale } from '../utilities/date-to-locale.utility';

const baseTranslationsEnglish = {
  literals: {
    login: 'Login',
    loading: 'Loading',
    submit: 'Submit',
    unkown: 'Unknown',
    comment: 'Comment',
    like: 'Like',
    viewSpot: 'View Spot',
    spot: 'Spot',
    home: 'Home',
    profile: 'Profile',
    leaderboard: 'Leaderboard',
    uploads: 'Uploads',
    upload: 'Upload',
    points: 'Points',
    likes: 'Likes',
    justNow: 'Just now',
    minutesAgo: 'minutes ago',
    hoursAgo: 'hours ago',
    daysAgo: 'days ago',
    someTimeAgo: 'Some time ago',
    populairity: 'Popularity',
    newest: 'Newest',
    list: 'List',
    map: 'Map',
    return: 'Return',
    close: 'Close',
    send: 'Send',
    cancel: 'Cancel',
    addComment: 'Add comment',
    andMoreComments: 'and more comments',
    andExMoreComments: (value: number) => `and ${value} more comments`,
    welcome: 'Welcome',
    welcomeBack: 'Welcome back',
    highlighted: 'Highlighted',
    publishContent: 'Publish content',
    viewContribution: 'View contribution',
    contribute: 'Contribute',
    contributeToX: (value?: string) => `Contribute to ${value}`,
    delete: 'Delete',
    dropYourFilesHere: 'Drop your files here',
    orClickToBrowse: 'or click to browse',
    titleYourWork: 'Title your work',
    contentPublished: 'Content published',
    editYourProfile: 'Edit your profile',
    saveChanges: 'Save changes',
    writeSomethingAboutYourself: 'Write something about yourself',
    enterTheOtherSide: 'Enter The Other Side',
    aboutTheLeaderboards: 'About the leaderboards',
    globalPopularCreators: 'Global popular creators',
    logout: 'Logout',
    edit: 'Edit',
    editYourSubmission: 'Edit your submission',
    feed: 'Feed',
    explore: 'Explore',
    aboutToss: 'About TOSS',
    privacyStatement: 'Privacy statement',
  },
  texts: {
    popularCreatorsForX: (value: string) => `Popular creators for ${value}`,
    xHasNoBio: (value?: string) =>
      `${value ?? 'user'} hasn't written anything about themself yet`,
    xPeopleAtSpot: (value: number) => `${value} people are visiting this place`,
    descriptionPlaceholder:
      'Tell something about what you are about to publish',
    waitForLogin: "Please wait while you're being logged in...",
    confirmDelete: 'Are you sure you want to delete this submission',
    onboardingTitle: 'Ready to come aboard?',
    onboardingText:
      'Hop on the platform to interact with people on The Other Side Space!',
    onboardingTextLong:
      "Hop on the platform to interact with people on The Other Side! You can upload your own content to spots, comment on other people's contributions, give stars and even earn points for your activity!",
    xHasNoSubmissions: (value?: string) =>
      `${value ?? 'User'} hasn't uploaded anything yet`,
    lorumIpsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nunc nisl aliquet nisl, eget aliquam nunc nisl eget nunc.',
    creatingNewSubmisionDescription:
      'You can contribute to this spot by publishing one or more documents. Published content is publicily available for-, and can be interacted with by all users.',
    editingYourSubmissionDescription:
      'You can edit the content of your submission here. You can change the title, description and the files you have uploaded.',
    imageSlideTitle: (index: number): string => {
      let title = '';
      switch (index) {
        case 0:
          title = 'Digital Twin of Vertigo';
          break;
        case 1:
          title = 'Share and Connect';
          break;
        case 2:
          title = 'In Vertigo...';
          break;
        case 3:
          title = 'On TOSS...';
          break;
        default:
          title = 'Empty title';
      }
      return title;
    },
    imageSlideDescription: (index: number): string => {
      let description = '';
      switch (index) {
        case 0:
          description =
            'TOSS is the other side space of Vertigo building, a digital twin.';
          break;
        case 1:
          description =
            'TOSS is a digital-physical bridge to facilitate interaction and exposure.';
          break;
        case 2:
          description =
            'You can scan QR codes in the building to TOSS to the other side.';
          break;
        case 3:
          description =
            'On TOSS, you can navigate the building and see what is happening in the Built Environment department of TU/e.';
          break;
        default:
          description = 'Empty description';
      }
      return description;
    },
    consentForLoggingIn:
      'I consent to processing my personal data gathered the platform in the way described in the information sheet.',
    leaderboardInfoDescription:
      'Welcome to the leaderboard section of The Other Side! This is where you can see where you stand among other users and compete to be the top creator. The leaderboard is based on the amount of likes and comments your submissions have received in the past month. This means the more engagement your submissions receive, the higher your score will be, and the higher you will rank on the leaderboard.\n\n' +
      'In addition to the global leaderboard, we also have leaderboards per spot. This means you can see how you rank among users in specific locations. This is a great way to see how you measure up against your local competition and to connect with other users in your area.\n\n' +
      'So, keep submitting quality content and engaging with other users to increase your chances of climbing the leaderboards. Good luck!',
  },
  abbreviations: {},
  templates: {
    updatedAt: (value: Date) => `Updated ${dateToLocale(value)}`,
  },
};

const translationsDutch: typeof baseTranslationsEnglish = {
  literals: {
    login: 'Inloggen',
    loading: 'Laden',
    submit: 'Verzenden',
    unkown: 'Onbekend',
    comment: 'Reageren',
    like: 'Like',
    viewSpot: 'Bekijk spot',
    spot: 'Spot',
    home: 'Home',
    profile: 'Profiel',
    leaderboard: 'Leaderboard',
    points: 'Punten',
    likes: 'Likes',
    uploads: 'Uploads',
    upload: 'Upload',
    justNow: 'Zojuist',
    minutesAgo: 'minuten geleden',
    hoursAgo: 'uur geleden',
    daysAgo: 'dagen geleden',
    someTimeAgo: 'Enige tijd geleden',
    populairity: 'Populariteit',
    newest: 'Nieuwste',
    list: 'Lijst',
    map: 'Kaart',
    return: 'Terug',
    send: 'Verzenden',
    close: 'Sluiten',
    cancel: 'Annuleren',
    addComment: 'Reactie toevoegen',
    andMoreComments: 'and meer reacties',
    andExMoreComments: (value: number) => `en ${value} meer reacties`,
    welcome: 'Welkom',
    welcomeBack: 'Welkom terug',
    highlighted: 'Uitgelicht',
    publishContent: 'Plaats bericht',
    viewContribution: 'Bekijk bijdrage',
    contribute: 'Draag bij',
    contributeToX: (value?: string) => `Draag bij aan ${value}`,
    delete: 'Verwijderen',
    dropYourFilesHere: 'Sleep je bestanden hierheen',
    orClickToBrowse: 'of klik om te bladeren',
    titleYourWork: 'Geef je werk een titel',
    contentPublished: 'Bericht geplaatst',
    editYourProfile: 'Bewerk je profiel',
    saveChanges: 'Wijzigingen opslaan',
    writeSomethingAboutYourself: 'Schrijf iets over jezelf',
    enterTheOtherSide: 'Betreed The Other Side',
    aboutTheLeaderboards: 'Over de leaderboards',
    globalPopularCreators: 'Globale populaire bijdragers',
    logout: 'Uitloggen',
    edit: 'Bewerken',
    editYourSubmission: 'Bewerk je inzending',
    feed: 'Feed',
    explore: 'Verkennen',
    aboutToss: 'Over TOSS',
    privacyStatement: 'Privacyverklaring',
  },
  texts: {
    popularCreatorsForX: (value: string) =>
      `Populaire bijdragers voor ${value}`,
    xHasNoBio: (value?: string) =>
      `${value ?? 'gebruiker'} heeft nog niets over zichzelf geschreven`,
    xPeopleAtSpot: (value: number) => `${value} people are visiting this place`,
    descriptionPlaceholder: 'Vertel iets over wat je gaat publiceren',
    waitForLogin: 'Even geduld terwijl je wordt ingelogd...',
    confirmDelete: 'Weet u zeker dat u deze inzending wilt verwijderen',
    onboardingTitle: 'Klaar om van start te gaan?',
    onboardingText:
      'Stap aan boord van de platform om te interacteren met mensen aan de andere kant!',
    onboardingTextLong:
      'Stap aan boord van de platform om te interacteren met mensen aan de andere kant! Je kunt je eigen content uploaden naar spots, reageren op bijdragen van anderen, sterren geven en zelfs punten verdienen voor je activiteit!',
    xHasNoSubmissions: (value?: string) =>
      `${value ?? 'Gebruiker'} heeft nog niets geüpload`,
    lorumIpsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies lacinia, nunc nisl aliquet nisl, eget aliquam nunc nisl eget nunc.',
    creatingNewSubmisionDescription:
      'Je kunt bijdragen aan deze spot door één of meerdere documenten te publiceren. De gepubliceerde documenten zijn dan openbaar beschikbaar voor- en kan door elke gebruiker mee geinteracteert worden.',
    editingYourSubmissionDescription:
      'Je kunt hier de inhoud van je inzending bewerken. Je kunt de titel, beschrijving en de bestanden die je hebt geüpload aanpassen.',
    imageSlideTitle: (index: number): string => {
      let title = '';
      switch (index) {
        case 0:
          title = 'Digitale tweeling van Vertigo';
          break;
        case 1:
          title = 'Deel en verbind';
          break;
        case 2:
          title = 'In Vertigo...';
          break;
        case 3:
          title = 'In TOSS...';
          break;
        default:
          title = 'Een lege titel';
      }
      return title;
    },
    imageSlideDescription: (index: number): string => {
      let description = '';
      switch (index) {
        case 0:
          description =
            'TOSS is de andere kant van het Vertigo-gebouw, een digitale tweeling.';
          break;
        case 1:
          description =
            'TOSS is een digitaal-fysieke brug om interactie en exposure te faciliteren.';
          break;
        case 2:
          description =
            'Je kunt QR-codes in het gebouw scannen om TOSS naar de andere kant te gaan.';
          break;
        case 3:
          description =
            'Op TOSS kun je door het gebouw navigeren en zien wat er gebeurt in de afdeling Built Environment van de TU/e.';
          break;
        default:
          description = 'Een lege beschrijving';
      }
      return description;
    },
    consentForLoggingIn:
      'Ik geef toestemming voor het verwerken van mijn persoonlijke gegevens die op het platform zijn verzameld op de manier zoals beschreven in het informatieblad.',
    leaderboardInfoDescription:
      'Welkom bij het leaderboard-gedeelte van The Other Side! Hier kun je zien waar je staat ten opzichte van andere gebruikers en strijden om de beste creator te worden. Het leaderboard is gebaseerd op het aantal vind-ik-leuks en reacties die je inzendingen de afgelopen maand hebben ontvangen. Dit betekent dat hoe meer betrokkenheid jouw inzendingen krijgen, hoe hoger jouw score zal zijn en hoe hoger je op het scorebord zult staan.\n\n' +
      'Naast het wereldwijde leaderboard hebben we ook leaderboards per spot. Dit betekent dat je kunt zien hoe jij scoort onder gebruikers op specifieke locaties. Dit is een geweldige manier om te zien hoe jij zich verhoudt tot jouw lokale concurrentie en om in contact te komen met andere gebruikers in jouw eigen regio.\n\n' +
      'Blijf dus inhoud van hoge kwaliteit indienen en in contact blijven met andere gebruikers om jouw kansen op het beklimmen van de leaderboards te vergroten. Succes!',
  },
  abbreviations: {},
  templates: {
    updatedAt: (value: Date) => `${dateToLocale(value)} Bijgewerkt `,
  },
};

type Translations = {
  [countryCode: string]: typeof baseTranslationsEnglish;
};

const translations: Translations = {
  'en-GB': baseTranslationsEnglish,
  'en-US': baseTranslationsEnglish,
  'nl-NL': translationsDutch,
};

const { language } = navigator;

const dictionary = translations[language] ?? baseTranslationsEnglish;

export { dictionary };
