import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './image-indicator.module.css';

type Props = {
  totalSlides: number;
  currentSlideIndex: number;
  handleOnClick: (index: number) => void;
};

const ImageIndicatorElement = ({
  totalSlides,
  currentSlideIndex,
  handleOnClick,
}: Props): JSX.Element => (
  <FlexElement>
    {Array.from(Array(totalSlides).keys()).map((index) => (
      <div
        key={index}
        onClick={() => handleOnClick(index)}
        className={join(
          styles.indicator,
          index === currentSlideIndex && styles.active,
        )}
      />
    ))}
  </FlexElement>
);

export { ImageIndicatorElement };
