import { ReactNode, useCallback } from 'react';
import { ISpot } from '@hulanbv/toss';
import { useNavigate } from 'react-router';
import { FlexElement } from '../flex-element/flex-element.component';
import { MenubarElement } from '../menubar-element/menubar-element.component';
import listMenuIconImage from '../../../assets/graphics/list-menu-item-icon.svg';
import styles from './screen-container-element.module.css';
import { HighlightableElement } from '../highlightable-element/highlightable-element.component';
import { useSheetContext } from '../../../domain/sheet/sheet-context.hook';
import { MapRendererElement } from '../map-renderer-element/map-renderer-element.component';
import { useTutorialContext } from '../../../domain/tutorial/tutorial-context.hook';
import { useAuthenticationContext } from '../../../domain/authentication/authentication-context.hook';

type Props = {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  coverImageUrl?: string;
  titleControls?: ReactNode;
  spot?: ISpot;
};

const ScreenContainerElement = ({
  children,
  title,
  subTitle,
  coverImageUrl,
  titleControls,
  spot,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticationContext();
  const { show: showSheet } = useSheetContext();
  const { hasSeenTutorial } = useTutorialContext();

  const handleShowMenuSheet = useCallback(() => {
    showSheet(
      <FlexElement verPadding={1.5}>
        <MenubarElement />
      </FlexElement>,
    );
  }, [showSheet]);

  return (
    <FlexElement>
      <FlexElement align="start" maxWidth={1000} fullWidth padding gap>
        <FlexElement attributes={{ className: styles.sidebarContainer }}>
          <MenubarElement />
        </FlexElement>
        <FlexElement column gap align="stretch" fullWidth>
          <div className={styles.applicationLogoContainer}>
            <FlexElement justify="space-between" align="center" fullWidth>
              {hasSeenTutorial && (
                <HighlightableElement onClick={handleShowMenuSheet}>
                  <FlexElement>
                    <img
                      className={styles.menuIcon}
                      src={listMenuIconImage}
                      width={28}
                      alt="Logo"
                    />
                  </FlexElement>
                </HighlightableElement>
              )}
              <FlexElement fullWidth justify="flex-end">
                <HighlightableElement onClick={() => navigate('/')}>
                  <FlexElement justify="flex-end">
                    <div className={styles.logoImage} />
                  </FlexElement>
                </HighlightableElement>
              </FlexElement>
            </FlexElement>
          </div>
          {coverImageUrl && (
            <img
              src={coverImageUrl}
              className={styles.coverImage}
              alt={title}
            />
          )}
          <FlexElement align="center" column gap={0.5}>
            <FlexElement isRelative fullWidth gap>
              {title && <h1>{title}</h1>}
              {titleControls && <FlexElement>{titleControls}</FlexElement>}
            </FlexElement>
            {spot && spot.area && (
              <div
                className={styles.expandedGalleryMap}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/map/spot/${spot._id}`);
                }}
              >
                <MapRendererElement
                  hasOwnBorderRadius
                  options={{
                    isInteractive: false,
                    initialCameraDistance: 15,
                    area: spot.area,
                    coordinates: spot.coordinates,
                  }}
                />
              </div>
            )}
            {subTitle && <h6>{subTitle}</h6>}
          </FlexElement>
          <div>{children}</div>
          {isAuthenticated === false && (
            <div
              style={{
                height: 120,
              }}
            />
          )}
        </FlexElement>
      </FlexElement>
    </FlexElement>
  );
};

export { ScreenContainerElement };
