import { IAsset } from '@hulanbv/toss';
import { join } from '../../../domain/common/utilities/join.utility';
import { useImageZoomContext } from '../../../domain/image-zoom/image-zoom-context.hook';
import styles from './asset-gallery-element.module.css';

type Props = {
  assets: IAsset[];
  isExpanded?: boolean;
};

const AssetGalleryElement = ({ assets, isExpanded }: Props): JSX.Element => {
  const { handleTouchStart } = useImageZoomContext();
  return (
    <div
      className={join(
        styles.element,
        isExpanded === true ? styles.expanded : styles.grid,
        assets.length === 1 && styles.single,
        assets.length === 2 && styles.double,
        assets.length >= 3 && styles.tripple,
      )}
    >
      {assets.slice(0, 3).map((asset) => (
        <img
          key={asset.id}
          src={asset.url}
          alt=""
          onTouchStart={handleTouchStart}
        />
      ))}
    </div>
  );
};

export { AssetGalleryElement };
