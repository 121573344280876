import { ISession } from '@hulanbv/toss';
import { IHttpOptions, IResponse } from 'nest-utilities-client';
import { useCallback, useState } from 'react';
import { authenticationService } from './authentication.service';

type UseAuthenticationHook = {
  session: ISession | null;
  isAuthenticated: boolean;
  isValidated: boolean;
  validate: (options?: IHttpOptions) => Promise<IResponse<ISession>>;
  logout: (options?: IHttpOptions) => Promise<IResponse<void>>;
};

const useAuthentication = (): UseAuthenticationHook => {
  const [session, setSession] = useState<ISession | null>(null);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const validate = useCallback(async (options?: IHttpOptions) => {
    setIsValidated(false);
    try {
      const response = await authenticationService.validate(options);
      setSession(response.data);
      setIsAuthenticated(true);
      return response;
    } catch (error) {
      setSession(null);
      setIsAuthenticated(false);
      throw error;
    } finally {
      setIsValidated(true);
    }
  }, []);

  const logout = useCallback(async (options?: IHttpOptions) => {
    try {
      const response = await authenticationService.logout(options);
      setSession(null);
      setIsAuthenticated(false);
      return response;
    } catch (error) {
      setSession(null);
      setIsAuthenticated(false);
      throw error;
    }
  }, []);

  const hook = {
    session,
    isAuthenticated,
    isValidated,
    validate,
    logout,
  };

  return hook;
};

export { useAuthentication };
