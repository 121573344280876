import { KeyboardEvent } from 'react';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { CardElement } from '../elements/card-element/card-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { InputTextAreaElement } from '../elements/input-text-area-element/input-text-area-element.component';
import { ProfilePictureElement } from '../elements/profile-picture-element/profile-picture-element.component';

type Props = {
  isMultiline?: boolean;
  name?: string;
  isDisabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  backgroundFlavour?: 'primary' | 'secondary';
  placeholder?: string;
  handleKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  handleTextChange?: (text: string) => void;
};

const UserDataTextareaTemplate = ({
  isMultiline,
  name,
  required,
  isDisabled = false,
  defaultValue,
  backgroundFlavour = 'primary',
  placeholder,
  handleTextChange,
  handleKeyDown,
}: Props): JSX.Element => {
  const { session } = useAuthenticationContext();

  return (
    <label style={{ width: '100%' }}>
      <CardElement flavour={backgroundFlavour} clickable>
        <FlexElement fullWidth gap={0.5} align="flex-start">
          <FlexElement align="flex-start">
            <ProfilePictureElement
              profileImage={session?.user?.profileImage}
              size={40}
            />
          </FlexElement>
          <FlexElement fullWidth column align="flex-start" gap={0.25}>
            <p>
              <strong>
                {session?.user?.firstName} {session?.user?.lastName}
              </strong>
            </p>
            <InputTextAreaElement
              defaultValue={defaultValue}
              name={name}
              isWithUserData
              isDisabled={isDisabled}
              placeholder={placeholder}
              isMultiline={isMultiline}
              handleTextChange={handleTextChange}
              handleKeyDown={handleKeyDown}
              required={required}
            />
          </FlexElement>
        </FlexElement>
      </CardElement>
    </label>
  );
};
export { UserDataTextareaTemplate };
