import { KeyboardEvent, useCallback, useState } from 'react';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { UserDataTextareaTemplate } from '../templates/user-data-textarea-template.component';
import { LoginWallTemplate } from '../templates/login-wall-template.component';

type Props = {
  onSubmit: (message: string) => void;
};

const CommentElement = ({ onSubmit }: Props): JSX.Element => {
  const [submissionComment, setSubmissionComment] = useState('');
  const { isAuthenticated } = useAuthenticationContext();
  const { show: showSheet } = useSheetContext();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && submissionComment.length > 0) {
        if (isAuthenticated === false) {
          showSheet(<LoginWallTemplate emoji="🎙️" />);
          return;
        }
        event.preventDefault();
        onSubmit(submissionComment);
      }
    },
    [isAuthenticated, onSubmit, showSheet, submissionComment],
  );

  return (
    <UserDataTextareaTemplate
      defaultValue={submissionComment}
      backgroundFlavour="secondary"
      placeholder={dictionary.literals.addComment}
      handleTextChange={setSubmissionComment}
      handleKeyDown={handleKeyDown}
    />
  );
};

export { CommentElement };
