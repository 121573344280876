import { ILeaderboardItem, ISubmission } from '@hulanbv/toss';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { useOnBottomReached } from '../../domain/common/hooks/use-on-bottom-reached.hook';
import { usePagination } from '../../domain/common/hooks/use-pagination.hook';
import { leaderboardService } from '../../domain/leaderboard/leaderboard.service';
import { submissionService } from '../../domain/submission/submission.service';
import { FeaturedUsersElement } from '../elements/featured-users-element/featured-users-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { HighlightableElement } from '../elements/highlightable-element/highlightable-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { SpinnerElement } from '../elements/spinner-element/spinner-element.component';
import { ViewSubmissionElement } from '../elements/view-submission-element/view-submission-element.component';
import { HomeToggleBarTemplate } from '../templates/home-toggle-bar-template.component';

const HomeListViewScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const [highlightedSubmission, setHighlightedSubmission] =
    useState<ISubmission | null>(null);
  const { session } = useAuthenticationContext();
  const [leaderboardItems, setLeaderboardItems] = useState<ILeaderboardItem[]>(
    [],
  );

  useEffect(() => {
    (async () => {
      const { data: items } =
        await leaderboardService.getPopularContentCreators({
          limit: 3,
        });
      setLeaderboardItems(items);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data: submission } =
        await submissionService.getHighlightedSubmissions({
          populate: [
            'author.profileImage',
            'assets',
            'spot.area.model',
            'spot.area.texture',
            'spot.area.spots',
            'spot.area.navigations',
            {
              path: 'likes',
              match: { authorId: session?.userId },
            },
            {
              path: 'comments',
              limit: 2,
              sort: ['-createdAt'],
              populate: [
                { path: 'author', populate: [{ path: 'profileImage' }] },
              ],
            },
          ],
          limit: 1,
        });
      setHighlightedSubmission(submission[0] ?? null);
    })();
  }, [session?.userId]);

  const paginateSubmissions = useCallback(
    async (skip: number, limit: number) => {
      const { data: submissions } = await submissionService.getAll({
        sort: ['-createdAt'],
        populate: [
          'author.profileImage',
          'assets',
          'spot.area.model',
          'spot.area.texture',
          'spot.area.spots',
          'spot.area.navigations',
          {
            path: 'likes',
            match: { authorId: session?.userId },
          },
          {
            path: 'comments',
            limit: 2,
            sort: ['-createdAt'],
            populate: [
              { path: 'author', populate: [{ path: 'profileImage' }] },
            ],
          },
        ],
        offset: skip,
        limit,
      });

      return submissions;
    },
    [session?.userId],
  );
  const {
    items: submissions,
    nextPage,
    isLoading,
  } = usePagination(paginateSubmissions, 10);
  useOnBottomReached(nextPage);

  return (
    <ScreenContainerElement>
      <FlexElement column gap fullWidth align={'stretch'}>
        <HomeToggleBarTemplate activeIndex={0} />

        <HighlightableElement
          onClick={() => navigate('/leaderboard/popular-creator')}
        >
          {leaderboardItems.length >= 3 && (
            <FeaturedUsersElement items={leaderboardItems} />
          )}
        </HighlightableElement>

        {highlightedSubmission && (
          <ViewSubmissionElement
            submission={highlightedSubmission}
            isHighlighted
          />
        )}
        {submissions.length > 0 &&
          submissions.map((submission) => (
            <ViewSubmissionElement
              key={submission.id}
              submission={submission}
            />
          ))}
        {isLoading === true && <SpinnerElement />}
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { HomeListViewScreen };
