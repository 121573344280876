import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { INuHeaders } from 'nest-utilities-client/distribution/interfaces/nu-headers.interface';
import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type CrudServiceHook<ModelType, HydrateType = null> = {
  useHttpOptions: (
    factory: () => IHttpOptions<ModelType> | undefined,
    dependencies?: DependencyList,
  ) => IHttpOptions<ModelType> | undefined;
  useGetAll: (httpOptions?: IHttpOptions<ModelType>) => {
    documents: ModelType[];
    hydrated: HydrateType[];
    headers: INuHeaders | undefined;
    error: string | null;
    isBusy: boolean;
    refresh: () => Promise<void>;
  };
  useGet(
    id?: string | null,
    httpOptions?: IHttpOptions<ModelType>,
  ): {
    document: ModelType | null;
    hydrated: HydrateType | null;
    headers: INuHeaders | undefined;
    error: string | null;
    isBusy: boolean;
    refresh: () => Promise<void>;
  };
};

// A hook which will provide crud service methods.
const useCrudService = <ModelType, HydrateType = null>(
  crudService: CrudService<ModelType, HydrateType>,
): CrudServiceHook<ModelType, HydrateType> => {
  // Hook which will memorize the http options for the service.
  const useHttpOptions = (
    factory: () => IHttpOptions<ModelType> | undefined,
    dependencies?: DependencyList,
  ): IHttpOptions<ModelType> | undefined =>
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We cannot know if the dependency list is exhaustive
    useMemo<IHttpOptions<ModelType> | undefined>(factory, dependencies ?? []);

  // Hook which will fetch all documents.
  const useGetAll = (httpOptions?: IHttpOptions<ModelType>) => {
    const [error, setError] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [response, setResponse] = useState<IResponse<
      ModelType[],
      HydrateType[]
    > | null>(null);

    const fetch = useCallback(async () => {
      setIsBusy(true);
      try {
        const documents = await crudService.getAll(httpOptions);
        setResponse(documents);
        setError(null);
      } catch (error) {
        setError(error as string);
      } finally {
        setIsBusy(false);
      }
    }, [httpOptions]);

    const documents = useMemo(() => response?.data ?? [], [response]);
    const hydrated = useMemo(() => response?.hydrated ?? [], [response]);
    const headers = useMemo(() => response?.headers ?? undefined, [response]);

    useEffect(() => {
      fetch();
    }, [httpOptions, fetch]);

    return { documents, headers, hydrated, error, isBusy, refresh: fetch };
  };

  // Hook which will fetch one document.
  const useGet = (id: string, httpOptions?: IHttpOptions<ModelType>) => {
    const [error, setError] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [response, setResponse] = useState<IResponse<
      ModelType,
      HydrateType
    > | null>(null);

    const fetch = useCallback(async (): Promise<void> => {
      setIsBusy(true);
      try {
        const document = await crudService.get(id, httpOptions);
        setResponse(document);
        setError(null);
      } catch (error) {
        setError(error as string);
      } finally {
        setIsBusy(false);
      }
    }, [id, httpOptions]);

    const document = useMemo(() => response?.data ?? null, [response]);
    const hydrated = useMemo(() => response?.hydrated ?? null, [response]);
    const headers = useMemo(() => response?.headers ?? undefined, [response]);

    useEffect(() => {
      fetch();
    }, [id, httpOptions, fetch]);

    return { document, headers, hydrated, error, isBusy, refresh: fetch };
  };

  return { useHttpOptions, useGetAll, useGet };
};

export { useCrudService };
