import { Fragment, useState } from 'react';
import { ActionButtonElement } from '../../components/elements/action-button-element/action-button-element.component';
import { FlexElement } from '../../components/elements/flex-element/flex-element.component';
import { useAuthenticationContext } from '../authentication/authentication-context.hook';
import { dictionary } from '../common/constants/dictionary.constants';
import { environment } from '../environment/environment.constants';
import { useTutorialContext } from '../tutorial/tutorial-context.hook';
import styles from './login-banner-renderer.module.css';
import { Checkbox } from '../../components/elements/checkbox-element/checkbox-element.component';

const LoginBannerRenderer = (): JSX.Element => {
  const { isAuthenticated, isValidated } = useAuthenticationContext();
  const [isAbleToLogin, setIsAbleToLogin] = useState(false);
  const { hasSeenTutorial } = useTutorialContext();

  if (
    isAuthenticated === true ||
    isValidated === false ||
    hasSeenTutorial === false
  ) {
    return <Fragment />;
  }

  const handleClickLogin = () => {
    const href = [environment.apiUrl, 'authentication', 'sso', 'login'].join(
      '/',
    );
    window.location.href = href;
  };

  return (
    <div className={styles.element}>
      <FlexElement padding gap>
        <FlexElement column fullWidth gap={0.5} align="start" maxWidth={600}>
          <h2>{dictionary.texts.onboardingTitle}</h2>
          <Checkbox
            label={dictionary.texts.consentForLoggingIn}
            attributes={{
              onChange: setIsAbleToLogin,
            }}
          />
        </FlexElement>
        <ActionButtonElement
          onClick={handleClickLogin}
          flavor="light-rounded"
          isDisabled={!isAbleToLogin}
        >
          <p>{dictionary.literals.login}</p>
        </ActionButtonElement>
      </FlexElement>
    </div>
  );
};

export { LoginBannerRenderer };
