import { useEffect, useRef } from 'react';
import styles from './progress-element.module.css';

type Props = {
  isFinished: boolean;
};

const ProgressElement = ({ isFinished }: Props): JSX.Element => {
  const progressElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFinished === true) {
      return undefined;
    }

    const interval = window.setInterval(() => {
      if (!progressElement.current) {
        return;
      }

      const widthNumber = Number(
        progressElement.current.style.width.replace('%', ''),
      );
      progressElement.current.style.width = `${
        widthNumber + (100 - widthNumber) * 0.1
      }%`;
    }, 100);

    return () => {
      window.clearInterval(interval);
    };
  }, [isFinished]);

  return (
    <div className={styles.progress}>
      <div
        ref={progressElement}
        style={isFinished ? { width: '100%' } : undefined}
        className={styles.progressFill}
      />
    </div>
  );
};

export { ProgressElement };
