import { ILeaderboardItem } from '@hulanbv/toss';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http.service';
import { environment } from '../environment/environment.constants';

class LeaderboardService extends CrudService<ILeaderboardItem> {
  constructor() {
    super([environment.apiUrl, 'leaderboards'].join('/'), httpService);
  }

  getVirtualNavigations(
    options?: IHttpOptions<ILeaderboardItem[]>,
  ): Promise<IResponse<ILeaderboardItem[]>> {
    return this.http.get(
      [this.controller, 'virtual-navigation'].join('/'),
      options,
    );
  }

  getContentCreators(
    options?: IHttpOptions<ILeaderboardItem[]>,
  ): Promise<IResponse<ILeaderboardItem[]>> {
    return this.http.get(
      [this.controller, 'content-creator'].join('/'),
      options,
    );
  }

  getContentReviewers(
    options?: IHttpOptions<ILeaderboardItem[]>,
  ): Promise<IResponse<ILeaderboardItem[]>> {
    return this.http.get(
      [this.controller, 'content-reviewer'].join('/'),
      options,
    );
  }

  getPopularContentCreators(
    options?: IHttpOptions<ILeaderboardItem[]>,
  ): Promise<IResponse<ILeaderboardItem[]>> {
    return this.http.get(
      [this.controller, 'popular-content-creator'].join('/'),
      options,
    );
  }

  getPopularContentCreatorsOfSpot(
    spotId?: string,
    options?: IHttpOptions<ILeaderboardItem[]>,
  ): Promise<IResponse<ILeaderboardItem[]>> {
    return this.http.get(
      [this.controller, 'popular-content-creator', 'spot', spotId].join('/'),
      options,
    );
  }
}

const leaderboardService = new LeaderboardService();

export { leaderboardService };
