import { useContext, useEffect } from 'react';
import { FloatingActionButtonOptions } from './floating-action-button-options.type';
import { FloatingActionButtonContext } from './floating-action-button.context';
import { useFloatingActionButton } from './floating-action-button.hook';

// Wraps the FloatingActionButtonContext in a hook.
function useFloatingActionButtonContext(
  options?: FloatingActionButtonOptions,
): ReturnType<typeof useFloatingActionButton> {
  const context = useContext(FloatingActionButtonContext);
  useEffect(() => {
    if (options) {
      context?.show(options);
    }
    return () => {
      if (options) {
        context?.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Lifecycle hook
  }, [options?.callback, options?.isHidden]);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useFloatingActionButtonContext };
