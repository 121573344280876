import { DragEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { IAsset } from '@hulanbv/toss';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './select-file-element.module.css';
import { FileDisplayElement } from '../file-display-element/file-display-element.component';
import { FlexElement } from '../flex-element/flex-element.component';

type Props = {
  isUploading: boolean;
  uploadedFiles: File[];
  preUploadedFiles?: IAsset[];
  onChangeUploadedFiles: (files: File[]) => void;
  onChangePreUploadedFiles?: (files: IAsset[]) => void;
};

const selectedDataUrlMap = new Map<File, string>();

const SelectFileElement = ({
  isUploading,
  uploadedFiles,
  preUploadedFiles,
  onChangeUploadedFiles,
  onChangePreUploadedFiles,
}: Props): JSX.Element => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [availablePreUploadedFiles, setAvailablePreUploadedFiles] = useState<
    IAsset[]
  >([]);
  const filesCount = selectedFiles.length + availablePreUploadedFiles.length;

  useEffect(() => {
    setAvailablePreUploadedFiles(preUploadedFiles ?? []);
  }, [preUploadedFiles]);

  const dragOverHandler = useCallback((event: DragEvent<HTMLLabelElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();

    setIsDraggedOver(true);
  }, []);

  const dragLeaveHandler = useCallback((event: DragEvent<HTMLLabelElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();

    setIsDraggedOver(false);
  }, []);

  const handleAssetRemove = useCallback(
    (selectedFile: string) => {
      // Remove the selected file from the selected files array
      const mutatedSelectedFiles = selectedFiles.filter(
        (file) => selectedDataUrlMap.get(file) !== selectedFile,
      );

      onChangeUploadedFiles(mutatedSelectedFiles);
      setSelectedFiles(mutatedSelectedFiles);
    },
    [onChangeUploadedFiles, selectedFiles],
  );

  const handlePreLoadedAssetRemove = useCallback(
    (selectedFile: IAsset) => {
      // Remove the preloaded file from the preloaded files array
      const mutatedPreUploadedFiles = availablePreUploadedFiles.filter(
        (file) => file.id !== selectedFile.id,
      );

      setAvailablePreUploadedFiles(mutatedPreUploadedFiles);
      onChangePreUploadedFiles?.(mutatedPreUploadedFiles);
    },
    [availablePreUploadedFiles, onChangePreUploadedFiles],
  );

  const addSelectedFiles = useCallback(
    (files: File[]) => {
      const filteredFiles = files.slice(0, 3 - selectedFiles.length);

      for (const file of filteredFiles) {
        const dataUrl =
          selectedDataUrlMap.get(file) ?? URL.createObjectURL(file);

        selectedDataUrlMap.set(file, dataUrl);
      }
      const mutatedSelectedFiles = selectedFiles.concat(filteredFiles);
      onChangeUploadedFiles(mutatedSelectedFiles);
      setSelectedFiles(mutatedSelectedFiles);
    },
    [onChangeUploadedFiles, selectedFiles],
  );

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      setIsDraggedOver(false);

      addSelectedFiles(Array.from(event.dataTransfer.files));
    },
    [addSelectedFiles],
  );

  const handleFileInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      addSelectedFiles(Array.from(event.currentTarget.files ?? []));

      // eslint-disable-next-line no-param-reassign -- We need to reset the selected files of the input
      event.currentTarget.value = '';
    },
    [addSelectedFiles],
  );

  return (
    <FlexElement
      fullWidth
      gap
      justify={filesCount === 0 ? 'center' : 'flex-start'}
      attributes={{
        className: join(
          isDraggedOver === true && styles.draggedOver,
          filesCount === 0 && styles.clickable,
        ),
        style: { overflowX: 'auto' },
      }}
    >
      {availablePreUploadedFiles.map((files, index) => (
        <FileDisplayElement
          key={index}
          dataUrl={files.url ?? ''}
          isUploading={isUploading}
          isUploaded={true}
          onRemoveClick={() => handlePreLoadedAssetRemove(files)}
        />
      ))}
      {selectedFiles.map((selectedfile, index) => (
        <FileDisplayElement
          key={index}
          dataUrl={selectedDataUrlMap.get(selectedfile) ?? ''}
          isUploading={isUploading}
          isUploaded={uploadedFiles.includes(selectedfile)}
          onRemoveClick={handleAssetRemove}
        />
      ))}
      {filesCount < 3 && (
        <label
          className={join(
            styles.placeholder,
            filesCount === 0 ? styles.box : styles.inline,
          )}
          onDrop={handleFileDrop}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
        >
          <input
            className={styles.input}
            type="file"
            accept=".jpg,.jpeg,.png"
            multiple={true}
            onChange={handleFileInputChange}
          />
          {filesCount === 0 && (
            <p>
              <strong>{dictionary.literals.dropYourFilesHere}</strong>
              <br />
              {dictionary.literals.orClickToBrowse}
            </p>
          )}
          {filesCount > 0 && <div className={styles.uploadIcon} />}
        </label>
      )}
    </FlexElement>
  );
};

export { SelectFileElement };
