import { useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useAuthenticationContext } from '../domain/authentication/authentication-context.hook';
import { dictionary } from '../domain/common/constants/dictionary.constants';
import { httpService } from '../domain/common/http.service';
import { FlexElement } from './elements/flex-element/flex-element.component';
import { SpinnerElement } from './elements/spinner-element/spinner-element.component';
import { HomeListViewScreen } from './screens/home-list-view-screen.component';
import { HomeMapViewScreen } from './screens/home-map-view-screen.component';
import { ProfileScreen } from './screens/profile-screen.component';
import { SpotScreen } from './screens/spot-screen.component';
import { SubmissionScreen } from './screens/submission-screen.component';
import { SpotPopularCreatorLeaderboardScreen } from './screens/spot-popular-creator-leaderboard-screen.component';
import { TutorialScreen } from './screens/tutorial-screen.component';
import { useTutorialContext } from '../domain/tutorial/tutorial-context.hook';
import { PopularCreatorLeaderboardScreen } from './screens/popular-creator-leaderboard-screen.component copy';
import { useSheetContext } from '../domain/sheet/sheet-context.hook';
import { AboutUsScreen } from './screens/about-us-screen.component';
import { PrivacyStatementScreen } from './screens/privacy-statement-screen.component';

// First entry point where the application is rendered.
const Application = (): JSX.Element => {
  const { validate, isValidated } = useAuthenticationContext();
  const { hasSeenTutorial } = useTutorialContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { show } = useSheetContext();

  useEffect(() => {
    (async () => {
      const errorMessage = searchParams.get('error');
      if (errorMessage) {
        show(decodeURIComponent(errorMessage));
        return;
      }

      try {
        const searchParamToken = searchParams.get('token');
        const localStorageToken = localStorage.getItem('token');
        if (searchParamToken) {
          searchParams.delete('token');
          setSearchParams(searchParams);
        }
        httpService.currentAuthorizationToken =
          searchParamToken || localStorageToken || '';
        const { data: session } = await validate();
        localStorage.setItem('token', session.token);
        // eslint-disable-next-line no-empty -- Being unable to validate the token is not a problem.
      } catch {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Lifecycle effect.
  }, [show]);

  if (isValidated === false) {
    return (
      <FlexElement gap={0.5} column minHeight="95vh">
        <SpinnerElement />
        <p>{dictionary.literals.loading}...</p>
      </FlexElement>
    );
  }

  if (hasSeenTutorial === false) {
    return (
      <Routes>
        <Route path="*" element={<TutorialScreen />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* TODO -- Move routes to config. */}
      <Route path="/" element={<HomeListViewScreen />} />
      <Route path="/sso/login" element={<HomeListViewScreen />} />
      <Route path="/spot/:spotId" element={<SpotScreen />} />
      <Route
        path="/leaderboard/popular-creator/spot/:spotId"
        element={<SpotPopularCreatorLeaderboardScreen />}
      />
      <Route
        path="/leaderboard/popular-creator"
        element={<PopularCreatorLeaderboardScreen />}
      />
      <Route path="/profile/:userId" element={<ProfileScreen />} />
      <Route path="/submission/:submissionId" element={<SubmissionScreen />} />
      <Route path="/about" element={<AboutUsScreen />} />
      <Route path="/privacy-statement" element={<PrivacyStatementScreen />} />
      <Route path="/map" element={<HomeMapViewScreen />} />
      <Route path="/map/:areaId" element={<HomeMapViewScreen />} />
      <Route path="/map/spot/:spotId" element={<HomeMapViewScreen />} />
      <Route
        path="/map/navigation/:areaNavigationId"
        element={<HomeMapViewScreen />}
      />
    </Routes>
  );
};

export { Application };
