import { Coordinates, IArea, IAreaNavigation, ISpot } from '@hulanbv/toss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { areaNavigationService } from '../../domain/area-navigation/area-navigation-service';
import { areaService } from '../../domain/area/area.service';
import { spotService } from '../../domain/spot/spot-service';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { MapRendererElement } from '../elements/map-renderer-element/map-renderer-element.component';
import { ScreenContainerElement } from '../elements/screen-container-element/screen-container-element.component';
import { HomeToggleBarTemplate } from '../templates/home-toggle-bar-template.component';

const HomeMapViewScreen = (): JSX.Element => {
  const { areaId, spotId, areaNavigationId } = useParams<
    'areaId' | 'spotId' | 'areaNavigationId'
  >();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const previousAreaId = searchParams.get('areaId');
  const [area, setArea] = useState<IArea | null>(null);
  const [spot, setSpot] = useState<ISpot | null>(null);
  const [areaNavigation, setAreaNavigation] = useState<IAreaNavigation | null>(
    null,
  );

  const uniqueKey = useMemo(
    () => `${area?.id}-${spot?.id}-${areaNavigation?.id}`,
    [area?.id, spot?.id, areaNavigation?.id],
  );

  const coordinateValuesFromUrl: Coordinates | null = useMemo(() => {
    const coordinates = searchParams.get('position');
    if (coordinates) {
      const [x, y, z] = coordinates
        .split(',')
        .map((value) => parseFloat(value));
      return [x ?? 0, y ?? 0, z ?? 0];
    }
    return null;
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      try {
        if (spotId) {
          const { data: spot } = await spotService.get(spotId, {
            populate: [
              'area.model',
              'area.texture',
              'area.spots',
              'area.navigations',
            ],
          });
          setSpot(spot);
          setArea(null);
          setAreaNavigation(null);
        } else if (areaId) {
          const { data: area } = await areaService.get(areaId, {
            populate: ['model', 'texture', 'spots', 'navigations'],
          });
          setArea(area);
          setSpot(null);
          setAreaNavigation(null);
        } else if (areaNavigationId) {
          const { data: areaNavigation } = await areaNavigationService.get(
            areaNavigationId,
            {
              populate: [
                'area.model',
                'area.texture',
                'area.spots',
                'area.navigations',
              ],
            },
          );
          setAreaNavigation(areaNavigation);
          setArea(null);
          setSpot(null);
        } else {
          // When no area, spot or areaNavigation is provided, we're going to
          // a random area.
          if ((previousAreaId?.length ?? 0) > 0) {
            const { data: area } = await areaService.get(previousAreaId ?? '', {
              populate: ['model', 'texture', 'spots', 'navigations'],
            });
            setArea(area);
            setSpot(null);
            setAreaNavigation(null);
            return;
          }

          const { data: areas } = await areaService.getAll({
            populate: ['model', 'texture', 'spots', 'navigations'],
            random: true,
            limit: 1,
          });
          if (areas.length > 0) {
            setArea(areas[0] ?? null);
            setSpot(null);
            setAreaNavigation(null);
          } else {
            throw new Error('No areas found');
          }
        }
      } catch {
        navigate('/');
      }
    })();
  }, [areaId, areaNavigationId, navigate, previousAreaId, spotId]);

  return (
    <ScreenContainerElement>
      <FlexElement column gap fullWidth align="stretch">
        <HomeToggleBarTemplate activeIndex={1} />
        <div style={{ height: '80vh', width: '100%' }}>
          {area && (
            <MapRendererElement
              key={uniqueKey}
              hasOwnBorderRadius
              showAreaDetails
              options={{
                isInteractive: true,
                area,
                coordinates: coordinateValuesFromUrl ?? area.startCoordinates,
              }}
            />
          )}
          {areaNavigation && areaNavigation.area && (
            <MapRendererElement
              key={uniqueKey}
              hasOwnBorderRadius
              showAreaDetails
              options={{
                isInteractive: true,
                area: areaNavigation.area,
                coordinates:
                  coordinateValuesFromUrl ?? areaNavigation.coordinates,
              }}
            />
          )}
          {spot && spot.area && (
            <MapRendererElement
              key={uniqueKey}
              hasOwnBorderRadius
              showAreaDetails
              options={{
                isInteractive: true,
                area: spot.area,
                coordinates: coordinateValuesFromUrl ?? spot.coordinates,
              }}
            />
          )}
        </div>
      </FlexElement>
    </ScreenContainerElement>
  );
};

export { HomeMapViewScreen };
