import { useNavigate } from 'react-router-dom';
import { ISubmission, ISubmissionLike } from '@hulanbv/toss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import { ProfilePictureElement } from '../profile-picture-element/profile-picture-element.component';
import { AssetGalleryElement } from '../asset-gallery-element/asset-gallery-element.component';
import { dictionary } from '../../../domain/common/constants/dictionary.constants';
import { HighlightableElement } from '../highlightable-element/highlightable-element.component';
import { dateToLocale } from '../../../domain/common/utilities/date-to-locale.utility';
import { MapRendererElement } from '../map-renderer-element/map-renderer-element.component';
import submissionCommentActionButtonGraphic from '../../../assets/graphics/submission-comment-action-button.svg';
import submissionSpotActionButtonGraphic from '../../../assets/graphics/submission-spot-action-button.svg';
import submissionLikeActionFillButtonGraphic from '../../../assets/graphics/submission-like-action-fill-button.svg';
import submissionlikeActionButtonGraphic from '../../../assets/graphics/submission-like-action-button.svg';
import submissionHighlightIconGraphic from '../../../assets/graphics/submission-highlight-icon.svg';
import styles from './submission-element.module.css';
import { useAuthenticationContext } from '../../../domain/authentication/authentication-context.hook';
import { submissionLikesService } from '../../../domain/submission-likes/submisson-likes.service';
import { useSheetContext } from '../../../domain/sheet/sheet-context.hook';
import { LoginWallTemplate } from '../../templates/login-wall-template.component';
import { submissionService } from '../../../domain/submission/submission.service';
import { useContextAwareNavigation } from '../../../domain/common/hooks/use-context-aware-navigation.hook';
import { TitleContentTemplate } from '../../templates/titled-content-template.component';
import { SubmissionFormTemplate } from '../../templates/submission-form-template.component';

type Props = {
  submission: ISubmission;
  isSubmissionExtended?: boolean;
  isHighlighted?: boolean;
};

// eslint-disable-next-line complexity -- complexity is needed for this component because of the amount of conditional rendering
const SubmissionElement = ({
  submission,
  isSubmissionExtended,
  isHighlighted,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { navigate: navigateToProfile } = useContextAwareNavigation(
    `/profile/${submission.author?._id}`,
  );
  const { navigate: navigateToSubmission } = useContextAwareNavigation(
    `/submission/${submission._id}`,
  );
  const { navigate: navigateToSpot, isCurrentPath } = useContextAwareNavigation(
    `/spot/${submission.spotId}`,
  );

  const { session, isAuthenticated } = useAuthenticationContext();
  const { show: showSheet, close: closeSheet } = useSheetContext();

  const [likeCount, setLikeCount] = useState(submission.likesCount);
  const [likes, setLikes] = useState<ISubmissionLike[]>(submission.likes ?? []);
  const [hasPendingRequest, setHasPendingRequest] = useState(false);
  const [hasLikedSubmission, setHasLikedSubmission] = useState(false);

  useEffect(() => {
    // checks if the user has liked the submission
    const hasUserLikedSubmission = likes.some(
      ({ userId }) => userId === session?.userId,
    );
    // if user has already liked the submission, set isLiked to true
    setHasLikedSubmission(hasUserLikedSubmission);
  }, [likes, session?.userId]);

  const handleClickLike = useCallback(async () => {
    if (isAuthenticated === false) {
      showSheet(<LoginWallTemplate emoji="🤩" />);
      return;
    }
    if (hasPendingRequest) {
      return;
    }
    setHasPendingRequest(true);
    if (hasLikedSubmission === true) {
      // filters out the current user's like
      const submissionLikeToRemove = likes.find(
        (like) => like.userId === session?.userId,
      );
      // removes the like from the state
      setLikes(likes.filter((like) => like.id !== submissionLikeToRemove?.id));
      // sets isLiked to false to show empty star
      setHasLikedSubmission(false);
      // removes the like from the state
      setLikeCount((likeCount) => likeCount - 1);
      // Deletes the like form the database
      await submissionLikesService.delete(submissionLikeToRemove?.id);
    } else {
      try {
        setHasLikedSubmission(true);
        setLikeCount((likeCount) => likeCount + 1);
        const response = await submissionLikesService.post({
          userId: session?.userId ?? '',
          submissionId: submission.id,
        });
        setLikes([response.data]);
      } catch (error) {
        setHasLikedSubmission(false);
      }
    }
    setHasPendingRequest(false);
  }, [
    hasLikedSubmission,
    hasPendingRequest,
    isAuthenticated,
    likes,
    session?.userId,
    showSheet,
    submission.id,
  ]);

  const handleClickDelete = useCallback(async () => {
    // TODO -- Implement confirmation dialog
    // eslint-disable-next-line no-alert -- TEMP fix for confirm dialog
    const isConfirmed = window.confirm(dictionary.texts.confirmDelete);
    if (!isConfirmed) {
      return;
    }
    await submissionService.delete(submission.id);
    // TODO -- Dont reload the page, but remove the submission from the list
    window.location.reload();
  }, [submission.id]);

  const processedDescription = useMemo(() => {
    if (!submission.description) {
      return null;
    }

    const regex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    const parts = submission.description.split(regex);

    if (parts.length === 1) {
      return parts[0];
    }

    return parts.map((part, index) => {
      if (part.match(regex)) {
        const url = part.match(/^www\.[^\s]+$/) ? `https://${part}` : part;
        return (
          <a key={index} href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        );
      }
      return part;
    });
  }, [submission.description]);

  const handleClickEdit = useCallback(() => {
    const onSubmit = async (formData: FormData) => {
      if (!session?.userId) {
        return;
      }
      formData.set('authorId', session.userId);
      formData.set('_id', submission.id);
      try {
        await submissionService.patch(formData);
        closeSheet();
        // TODO -- Dont reload the page, but update the submission in the list
        window.location.reload();
      } catch {
        // TODO -- show error message
      }
    };

    showSheet(
      <TitleContentTemplate
        title={dictionary.literals.editYourSubmission}
        description={dictionary.texts.editingYourSubmissionDescription}
      >
        <SubmissionFormTemplate onSubmit={onSubmit} submission={submission} />
      </TitleContentTemplate>,
    );
  }, [closeSheet, session?.userId, showSheet, submission]);

  return (
    <FlexElement column gap fullWidth align="start">
      <FlexElement gap justify="space-between" fullWidth>
        <HighlightableElement onClick={navigateToProfile}>
          <FlexElement gap>
            <ProfilePictureElement
              profileImage={submission.author?.profileImage}
              size={35}
            />
            <FlexElement column align="start" grow={1}>
              <h5>
                {submission.author?.firstName} {submission.author?.lastName}
              </h5>
              <h6>
                {isHighlighted
                  ? dictionary.literals.highlighted
                  : dateToLocale(submission.createdAt)}
                <span>
                  {submission.createdAt !== submission.updatedAt
                    ? ` - ${dictionary.templates.updatedAt(
                        new Date(submission.updatedAt ?? ''),
                      )}`
                    : ''}
                </span>
              </h6>
            </FlexElement>
          </FlexElement>
        </HighlightableElement>
        {isHighlighted && (
          <FlexElement>
            <img
              className={styles.highlightIcon}
              src={submissionHighlightIconGraphic}
              alt={dictionary.literals.highlighted}
            />
          </FlexElement>
        )}
      </FlexElement>

      {!isSubmissionExtended && <p>{submission.title}</p>}
      {isSubmissionExtended && (
        <FlexElement column gap={0.5} align={'flex-start'}>
          <h5>{submission.title}</h5>
          <p>{processedDescription}</p>
        </FlexElement>
      )}
      {submission.assets && (
        <div className={styles.assets} onClick={navigateToSubmission}>
          <FlexElement gap={0.25} column fullWidth>
            <AssetGalleryElement
              assets={submission.assets}
              isExpanded={isSubmissionExtended}
            />
            {isSubmissionExtended &&
              submission.spot &&
              submission.spot.area && (
                <div
                  className={styles.expandedGalleryMap}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/map/spot/${submission.spotId}`);
                  }}
                >
                  <MapRendererElement
                    hasOwnBorderRadius={false}
                    options={{
                      isInteractive: false,
                      initialCameraDistance: 20,
                      area: submission.spot.area,
                      coordinates: submission.spot.coordinates,
                    }}
                  />
                </div>
              )}
          </FlexElement>
          {!isSubmissionExtended && submission.spot && submission.spot.area && (
            <div
              className={styles.map}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/map/spot/${submission.spotId}`);
              }}
            >
              <MapRendererElement
                hasOwnBorderRadius
                options={{
                  isInteractive: false,
                  initialCameraDistance: 15,
                  area: submission.spot.area,
                  coordinates: submission.spot.coordinates,
                }}
              />
            </div>
          )}
        </div>
      )}
      <FlexElement horPadding={0.25} gap={0.75}>
        <HighlightableElement onClick={handleClickLike}>
          <FlexElement gap={0.3}>
            <img
              height={20}
              src={
                hasLikedSubmission
                  ? submissionLikeActionFillButtonGraphic
                  : submissionlikeActionButtonGraphic
              }
              alt={dictionary.literals.like}
            />
            <h4>{likeCount}</h4>
          </FlexElement>
        </HighlightableElement>
        <HighlightableElement onClick={navigateToSubmission}>
          <FlexElement gap={0.3}>
            <img
              height={20}
              src={submissionCommentActionButtonGraphic}
              alt={dictionary.literals.comment}
            />
            <h4>{submission.commentsCount}</h4>
          </FlexElement>
        </HighlightableElement>
        {submission.spotId && isCurrentPath === false && (
          <HighlightableElement onClick={navigateToSpot}>
            <FlexElement gap={0.5}>
              <img
                height={20}
                src={submissionSpotActionButtonGraphic}
                alt={dictionary.literals.viewSpot}
              />
              <h4>{dictionary.literals.viewSpot}</h4>
            </FlexElement>
          </HighlightableElement>
        )}
        {submission.authorId === session?.userId && (
          <>
            <HighlightableElement onClick={handleClickDelete}>
              <FlexElement gap={0.5}>
                <h4>{dictionary.literals.delete}</h4>
              </FlexElement>
            </HighlightableElement>
            <HighlightableElement onClick={handleClickEdit}>
              <FlexElement gap={0.5}>
                <h4>{dictionary.literals.edit}</h4>
              </FlexElement>
            </HighlightableElement>
          </>
        )}
      </FlexElement>
    </FlexElement>
  );
};

export { SubmissionElement };
