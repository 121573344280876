/* eslint-disable complexity -- File is too complex */
import { clamp, degToRad, lerp, radToDeg } from 'three/src/math/MathUtils';
import { Coordinates, IArea, SpatialDocument } from '@hulanbv/toss';
// prettier-ignore
import { AmbientLight, Clock, Color, DirectionalLight, Mesh, MeshLambertMaterial, Object3D, ObjectLoader, PerspectiveCamera, Raycaster, Scene, Sprite, SpriteMaterial, TextureLoader, Vector2, Vector3, WebGLRenderer } from 'three';
import avatarFrontLeftMap from '../../../assets/textures/avatar-front-left-map.png';
import avatarBackLeftMap from '../../../assets/textures/avatar-back-left-map.png';
import avatarFrontRightMap from '../../../assets/textures/avatar-front-right-map.png';
import avatarBackRightMap from '../../../assets/textures/avatar-back-right-map.png';
import avatarShadowMap from '../../../assets/textures/avatar-shadow-map.png';
import spotMarkerMap from '../../../assets/textures/spot-marker-map.png';
import navigationMarkerMap from '../../../assets/textures/navigation-marker-map.png';
import { GizmosRay } from './gizmos';
import { environment } from '../../../domain/environment/environment.constants';

type GenericFunction<ValueType> = (value: ValueType) => void;
type Options = {
  isInteractive: boolean;
  initialCameraDistance?: number;
  area: IArea;
  coordinates: Coordinates;
};
type MapRendererType = null | {
  mount: (element: HTMLDivElement) => void;
  unmount: () => void;
  onClickMarker: Set<GenericFunction<SpatialDocument>>;
};

// Shared constants
const isDebugging = environment.environment === 'DEV';
const objectLoader = new ObjectLoader();
const textureLoader = new TextureLoader();
const whiteColor = new Color(0xffffff);
const greenColor = new Color(0x626b62);
const yellowColor = new Color(0xbeb79d);
const upVector2 = new Vector3(0, 2, 0);
const upVector1h = new Vector3(0, 1.5, 0);
const downVector = new Vector3(0, -1, 0);
const forwardVector = new Vector3(0, 0, 1);
const backwardVector = new Vector3(0, 0, -1);
const leftVector = new Vector3(-1, 0, 0);
const rightVector = new Vector3(1, 0, 0);
const initialCameraRotation = degToRad(45);
const maxCameraRotationOffset = degToRad(90);
const minInteractionDistance = 2;
const maxAvatarFallingHeight = 3;
const maxWallDistance = 2;

// Textures and materials
const avatarFrontLeftTexture = textureLoader.load(avatarFrontLeftMap);
const avatarBackLeftTexture = textureLoader.load(avatarBackLeftMap);
const avatarFrontRightTexture = textureLoader.load(avatarFrontRightMap);
const avatarBackRightTexture = textureLoader.load(avatarBackRightMap);
const avatarShadowTexture = textureLoader.load(avatarShadowMap);
const spotMarkerTexture = textureLoader.load(spotMarkerMap);
const navigationMarkerTexture = textureLoader.load(navigationMarkerMap);
const avatarFrontLeftSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: avatarFrontLeftTexture,
  transparent: true,
});
const avatarBackLeftSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: avatarBackLeftTexture,
  transparent: true,
});
const avatarFrontRightSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: avatarFrontRightTexture,
  transparent: true,
});
const avatarBackRightSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: avatarBackRightTexture,
  transparent: true,
});
const avatarShadowSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: avatarShadowTexture,
  transparent: true,
});
const spotMarkerSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: spotMarkerTexture,
  transparent: true,
});
const navigationMarkerSpriteMaterial = new SpriteMaterial({
  color: whiteColor,
  map: navigationMarkerTexture,
  transparent: true,
});

// Utility function to get the screen space position of a mouse event
const getScreenSpacePosition = (event: MouseEvent, screen: HTMLElement) => {
  const rect = screen.getBoundingClientRect();
  return {
    x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
    y: ((event.clientY - rect.top) / rect.height) * -2 + 1,
  } as Vector2;
};

// Utility function to create a sprite
const createEntitySprite = (spriteMaterial: SpriteMaterial) => {
  const sprite = new Sprite(spriteMaterial);
  sprite.scale.setScalar(2.5);
  sprite.center.set(0.5, 0);
  sprite.renderOrder = 2;
  return sprite;
};

// The renderer itself
const MapRenderer = (options: Options): MapRendererType => {
  let element: HTMLDivElement | null = null;
  const onClickMarker = new Set<GenericFunction<SpatialDocument>>();
  const { area, coordinates, isInteractive, initialCameraDistance } = options;

  // Initial setup
  const webGlRenderer = new WebGLRenderer({ antialias: true, alpha: true });
  webGlRenderer.shadowMap.enabled = true;
  const scene = new Scene();
  const clock = new Clock();

  // Camera
  let targetCameraRotation = initialCameraRotation;
  const camera = (() => {
    let cameraRotation = initialCameraRotation + maxCameraRotationOffset;
    const cameraDistance = initialCameraDistance ?? 45;
    const anchorObject = new Object3D();
    scene.add(anchorObject);
    const perspectiveCamera = new PerspectiveCamera(30, 1, 0.1, 2000);
    perspectiveCamera.position.set(0, cameraDistance, -cameraDistance);
    perspectiveCamera.rotation.set(degToRad(45), degToRad(180), 0);
    anchorObject.add(perspectiveCamera);
    // Update camera rotation to the target rotation
    const update = (deltaTime: number) => {
      cameraRotation = lerp(cameraRotation, targetCameraRotation, deltaTime);
      anchorObject.rotation.y = cameraRotation;
      anchorObject.position.lerp(avatar.container.position, deltaTime * 2);
    };
    // Raycasting for mouse interaction
    const raycaster = new Raycaster();
    const raycast = (position: Vector2) => {
      raycaster.setFromCamera(position, perspectiveCamera);
      const intersectables = [...markerObjects, ...areaModelMeshes];
      return raycaster.intersectObjects(intersectables)[0] ?? null;
    };
    // Utility functions to get the direction of the camera
    const getDirection = (relative: Vector3) =>
      new Vector3(
        Math.sin(cameraRotation) * relative.z +
          Math.cos(cameraRotation) * relative.x,
        relative.y,
        Math.cos(cameraRotation) * relative.z -
          Math.sin(cameraRotation) * relative.x,
      );
    // Utility function to get a relative angle of the camera
    const getAngle = () => cameraRotation;
    return { perspectiveCamera, update, raycast, getDirection, getAngle };
  })();

  // Lights
  const castedDirectionalLight = new DirectionalLight(yellowColor, 1);
  castedDirectionalLight.position.set(-10, 10, 10);
  camera.perspectiveCamera.add(castedDirectionalLight);
  const directionalLight = new DirectionalLight(yellowColor, 1);
  scene.add(directionalLight);
  const ambientLight = new AmbientLight(greenColor, 1);
  scene.add(ambientLight);

  // Marker objects
  const markerObjects = (() => {
    const markerObjects: Object3D[] = [];
    const createMarker = (
      document: SpatialDocument,
      spriteMaterial: SpriteMaterial,
    ) => {
      const markerSprite = createEntitySprite(spriteMaterial);
      markerSprite.position.set(...document.coordinates);
      markerSprite.userData = {
        markerDocument: document,
        distance: 999,
      };
      scene.add(markerSprite);
      markerObjects.push(markerSprite);
    };
    area.spots?.forEach((spot) => createMarker(spot, spotMarkerSpriteMaterial));
    area.navigations?.forEach((navigation) =>
      createMarker(navigation, navigationMarkerSpriteMaterial),
    );
    return markerObjects;
  })();

  // Avatar
  const avatar = (() => {
    let isMovingToDestination = true;
    let angle = 0;
    const destination = new Vector3(...coordinates);
    const container = new Object3D();
    const avatarShadowSprite = createEntitySprite(avatarShadowSpriteMaterial);
    const spriteContainer = new Object3D();
    const avatarFrontLeftSprite = createEntitySprite(
      avatarFrontLeftSpriteMaterial,
    );
    const avatarBackLeftSprite = createEntitySprite(
      avatarBackLeftSpriteMaterial,
    );
    const avatarFrontRightSprite = createEntitySprite(
      avatarFrontRightSpriteMaterial,
    );
    const avatarBackRightSprite = createEntitySprite(
      avatarBackRightSpriteMaterial,
    );
    const raycaster = new Raycaster();
    container.position.set(...coordinates);
    container.visible = isInteractive;
    spriteContainer.add(avatarFrontLeftSprite);
    spriteContainer.add(avatarBackLeftSprite);
    spriteContainer.add(avatarFrontRightSprite);
    spriteContainer.add(avatarBackRightSprite);
    container.add(spriteContainer);
    container.add(avatarShadowSprite);
    scene.add(container);
    // Update avatar position
    const floorRaycasterGizmosRay = GizmosRay(scene, 2);
    const update = (deltaTime: number) => {
      if (!isInteractive) {
        return;
      }
      // Hovering animation
      const hoveringOffset = (Math.sin(clock.elapsedTime * 4) + 1.1) / 4;
      spriteContainer.position.y = hoveringOffset;
      // Keyboard input
      if (keyboard.keysPressed.size > 0) {
        // When the ENTER key is pressed, interact with the nearest marker
        if (keyboard.keysPressed.has('Enter')) {
          for (const markerObject of markerObjects) {
            const { markerDocument, distance } = markerObject.userData;
            if (distance && distance < minInteractionDistance) {
              onClickMarker.forEach((callback) => callback(markerDocument));
              break;
            }
          }
          return;
        }
        // When any of the direction keys are pressed, move the avatar
        const destination = container.position.clone();
        if (keyboard.keysPressed.has('KeyW')) {
          destination.add(camera.getDirection(forwardVector));
        } else if (keyboard.keysPressed.has('KeyS')) {
          destination.add(camera.getDirection(backwardVector));
        }
        if (keyboard.keysPressed.has('KeyA')) {
          destination.add(camera.getDirection(rightVector));
        } else if (keyboard.keysPressed.has('KeyD')) {
          destination.add(camera.getDirection(leftVector));
        }
        setDestination(destination);
      }
      // Set the corrosponding sprite visible based on the angle of both the
      // camera and the avatar
      angle = -Math.atan2(
        destination.x - container.position.x,
        destination.z - container.position.z,
      );
      angle = Math.round(radToDeg(angle + camera.getAngle()) % 360);
      while (angle < 0) {
        angle += 360;
      }
      avatarFrontLeftSprite.visible = angle >= 180 && angle < 270;
      avatarBackLeftSprite.visible = angle >= 270 && angle < 360;
      avatarFrontRightSprite.visible = angle >= 90 && angle < 180;
      avatarBackRightSprite.visible = angle >= 0 && angle < 90;
      // If the avatar is moving, move it towards the destination
      if (!isMovingToDestination) {
        return;
      }
      destination.y = container.position.y;
      const direction = destination.clone().sub(container.position).normalize();
      // When the avatar is floating above the ground, move it down
      raycaster.set(container.position.clone().add(upVector2), downVector);
      if (isDebugging) {
        floorRaycasterGizmosRay(raycaster);
      }
      const groundIntersections = raycaster.intersectObjects(areaModelMeshes);
      if (groundIntersections[0]) {
        container.position.y = groundIntersections[0].point.y + 0.1;
      }
      if (!hasClearence(direction)) {
        isMovingToDestination = false;
        return;
      }
      const distance = container.position.distanceTo(destination);
      const speed = Math.min(distance, 1) * 6;
      container.position.add(direction.multiplyScalar(deltaTime * speed));
      // If the avatar has reached the destination, stop moving
      if (distance < 0.1) {
        isMovingToDestination = false;
      }
      for (const marker of markerObjects) {
        marker.userData.distance = marker.position.distanceTo(
          container.position,
        );
        const scalar = minInteractionDistance + 3 - marker.userData.distance;
        marker.scale.setScalar(clamp(scalar, 2.5, 2.75));
      }
    };
    // Check if the avatar has clearence to move in a direction
    const wallRaycasterGizmosRay = GizmosRay(scene, maxWallDistance);
    const edgeRaycasterGizmosRay = GizmosRay(scene, maxAvatarFallingHeight);
    const hasClearence = (direction: Vector3) => {
      const origin = container.position.clone().add(upVector1h);
      raycaster.set(origin, direction);
      if (isDebugging) {
        wallRaycasterGizmosRay(raycaster);
      }
      const wallHits = raycaster.intersectObjects(areaModelMeshes);
      const isHittingWall =
        wallHits[0] && wallHits[0].distance < maxWallDistance;
      origin.add(direction.clone().multiplyScalar(0.5));
      raycaster.set(origin, downVector);
      if (isDebugging) {
        edgeRaycasterGizmosRay(raycaster);
      }
      const edgeHits = raycaster.intersectObjects(areaModelMeshes);
      const isHittingEdge =
        edgeHits[0] && edgeHits[0].distance < maxAvatarFallingHeight;
      return !isHittingWall && isHittingEdge;
    };
    // Set the destination of the avatar
    const setDestination = (position?: Vector3) => {
      if (position) {
        destination.set(position.x, position.y, position.z);
      }
      isMovingToDestination = true;
    };
    return { container, update, setDestination };
  })();

  // Area
  const areaModelMeshes: Mesh[] = [];
  (async () => {
    const modelTexture = await textureLoader.loadAsync(area.texture?.url ?? '');
    const modelObject = await new Promise<Object3D>((resolve) => {
      objectLoader.load(area.model?.url ?? '', resolve);
    });
    const modelObjectMeshes = [modelObject, ...modelObject.children] as Mesh[];
    const modelMaterial = new MeshLambertMaterial({
      map: modelTexture,
      color: whiteColor,
    });
    for (const mesh of modelObjectMeshes) {
      mesh.material = modelMaterial;
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      areaModelMeshes.push(mesh);
      scene.add(mesh);
    }
    avatar.setDestination();
  })();

  // Keyboard
  const keyboard = (() => {
    const keysPressed = new Set<string>();
    const handleKeyDown = (keyboardEvent: KeyboardEvent) =>
      keysPressed.add(keyboardEvent.code);
    const handleKeyUp = (keyboardEvent: KeyboardEvent) =>
      keysPressed.delete(keyboardEvent.code);
    return { keysPressed, handleKeyDown, handleKeyUp };
  })();

  // Pointer
  const pointer = (() => {
    let isPointerDown = false;
    let dragDistanceX = 0;
    let lastPositionX = 0;
    // Pointer events
    const handlePointerDown = (event: PointerEvent) => {
      event.preventDefault();
      dragDistanceX = 0;
      lastPositionX = event.clientX;
      isPointerDown = true;
    };
    const handlePointerMove = (event: PointerEvent) => {
      if (!isPointerDown) {
        return;
      }
      event.preventDefault();
      dragDistanceX += Math.abs(event.clientX - lastPositionX);
      const deltaPosition = lastPositionX - event.clientX;
      targetCameraRotation = clamp(
        targetCameraRotation + deltaPosition / 50,
        initialCameraRotation - maxCameraRotationOffset,
        initialCameraRotation + maxCameraRotationOffset,
      );
      lastPositionX = event.clientX;
    };
    const handlePointerUp = (event: PointerEvent) => {
      event.preventDefault();
      isPointerDown = false;
      if (dragDistanceX > 10 || !element) {
        return;
      }
      const position = getScreenSpacePosition(event, element);
      const intersection = camera.raycast(position);
      if (!intersection) {
        return;
      }
      const { distance, markerDocument } = intersection.object.userData;
      if (!distance || distance > minInteractionDistance) {
        avatar.setDestination(intersection.point);
        return;
      }
      if (markerDocument) {
        onClickMarker.forEach((callback) => callback(markerDocument));
      }
    };
    return { handlePointerDown, handlePointerMove, handlePointerUp };
  })();

  // Update
  let updateAnimationFrameId = -1;
  const update = () => {
    const deltaTime = clock.getDelta();
    camera.update(deltaTime);
    avatar.update(deltaTime);
    webGlRenderer.render(scene, camera.perspectiveCamera);
    updateAnimationFrameId = window.requestAnimationFrame(update);
  };

  // Mounting and unmounting
  const mount = (targetElement: HTMLDivElement) => {
    element = targetElement;
    const [width, height] = [element.offsetWidth, element.offsetHeight];
    element.appendChild(webGlRenderer.domElement);
    webGlRenderer.setSize(width, height);
    webGlRenderer.setPixelRatio(window.devicePixelRatio);
    camera.perspectiveCamera.aspect = width / height;
    camera.perspectiveCamera.updateProjectionMatrix();
    window.cancelAnimationFrame(updateAnimationFrameId);
    updateAnimationFrameId = window.requestAnimationFrame(update);
    element.addEventListener('pointerdown', pointer.handlePointerDown);
    element.addEventListener('pointermove', pointer.handlePointerMove);
    window.addEventListener('pointerup', pointer.handlePointerUp);
    window.addEventListener('keydown', keyboard.handleKeyDown);
    window.addEventListener('keyup', keyboard.handleKeyUp);
  };

  const unmount = () => {
    element?.removeChild(webGlRenderer.domElement);
    webGlRenderer.dispose();
    webGlRenderer.forceContextLoss();
    window.cancelAnimationFrame(updateAnimationFrameId);
    element?.removeEventListener('pointerdown', pointer.handlePointerDown);
    element?.removeEventListener('pointermove', pointer.handlePointerMove);
    window.removeEventListener('pointerup', pointer.handlePointerUp);
    window.removeEventListener('keydown', keyboard.handleKeyDown);
    window.removeEventListener('keyup', keyboard.handleKeyUp);
  };

  return { mount, unmount, onClickMarker };
};

export { MapRenderer };
export type { Options as MapRendererOptions, MapRendererType };
/* eslint-enable complexity -- File is too complex */
