import { useLocalStorage } from '../common/hooks/use-local-storage.hook';

type ITutorialHook = {
  hasSeenTutorial: boolean;
  setHasSeenTutorial: (value: boolean) => void;
};

function useTutorial(): ITutorialHook {
  const [hasSeenTutorial, setHasSeenTutorial] = useLocalStorage(
    'has-seen-tutorial',
    false,
  );

  return {
    hasSeenTutorial: hasSeenTutorial ?? false,
    setHasSeenTutorial,
  };
}

export { useTutorial };
