import { FlexElement } from '../flex-element/flex-element.component';
import { ProgressElement } from '../progress-element/progress-element.component';
import styles from './file-display-element.module.css';

type Props = {
  dataUrl: string;
  isUploading: boolean;
  isUploaded: boolean;
  onRemoveClick?: (selectedFile: string) => void;
};

const FileDisplayElement = ({
  dataUrl,
  isUploading,
  isUploaded,
  onRemoveClick,
}: Props): JSX.Element => (
  <FlexElement isRelative>
    <img src={dataUrl} className={styles.image} alt={dataUrl} />
    {isUploading === false && (
      <div
        className={styles.removeButton}
        onClick={() => onRemoveClick?.(dataUrl)}
      />
    )}
    {isUploading && <ProgressElement isFinished={isUploaded} />}
  </FlexElement>
);

export { FileDisplayElement };
